import { useState } from "react";
import axiosClient from "../common/api/request";

const useInviteFriend = (closeModal, from) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const sendInvitation = () => {
    axiosClient
      .post("invitations/", {
        email,
        message,
      })
      .then(() => {
        if (from === "Home") {
          setEmail("");
          setMessage("");
          closeModal();
        } else {
          setEmail("");
          setMessage("");
          closeModal();
        }
      })
      .catch(() => {
        closeModal();
      });
  };
  return {
    email,
    setEmail,
    message,
    setMessage,
    sendInvitation,
  };
};

export default useInviteFriend;
