import {getSessionsSuccess, getSessions, getSessionsError } from "../actions";
import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";

export function* getSessionsSaga({ params }) {
  yield put(getSessions());
  try {
    const response = yield call(routes.getSessions, params);
    yield put(getSessionsSuccess(response));
  } catch (error) {
    yield put(getSessionsError(error));
  }
}

