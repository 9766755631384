import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LocalStorage } from "../common/localStorage";

const useQuestion8 = (number) => {
  const storedAnswers = LocalStorage.getFromLocalStorage("scenarios");
  const [answer, setAnswerState] = useState(
    (storedAnswers && storedAnswers[number]) || null
  );
  const setAnswer = (value) => {
    setAnswerState(value);
    LocalStorage.updateLocalStorage("scenarios", { [number]: value });
  };

  useEffect(() => {
    setAnswerState(storedAnswers && storedAnswers[number] || null);
  }, [number])
  const { questionId, scenarioId } = useParams();
  return {
    answer,
    questionId,
    scenarioId,
    setAnswer,
  };
};
export default useQuestion8;
