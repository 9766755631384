import * as actionTypes from "../actionTypes";

export const initSignIn = (userCredentials) => ({
  type: actionTypes.INIT_SIGNIN,
  payload: {
    ...userCredentials,
  },
});

export const signIn = () => ({
  type: actionTypes.SIGNIN,
});

export const signInSuccess = (payload) => ({
  type: actionTypes.SIGNIN_SUCCESS,
  payload,
});

export const signInError = (error) => ({
  type: actionTypes.SIGNIN_FAIL,
  payload: {
    error: error?.response?.data,
  },
});

export const initSignUp = (userCredentials) => ({
  type: actionTypes.INIT_SIGNUP,
  payload: {
    ...userCredentials,
  },
});

export const signUp = () => ({
  type: actionTypes.SIGNUP,
});

export const signUpSuccess = (payload) => ({
  type: actionTypes.SIGNUP_SUCCESS,
  payload,
});

export const signupSuccessReset = () => ({
  type: actionTypes.SIGNUP_SUCCESS_RESET,
});

export const signUpError = (error) => ({
  type: actionTypes.SIGNUP_FAIL,
  payload: {
    error: error?.response?.data,
  },
});

export const signOut = () => ({
  type: actionTypes.SIGN_OUT,
});
