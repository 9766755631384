import React from "react";
import { sessions, months } from "../../../../../../constants/AppConstants";
import { decideTime } from "../../../../../../common/utils";
import "./ActiveSessions.scss";
import useSessionsHistory from "../../../../../../hooks/useSessionsHistory";

const ActiveSessions = () => {
  const monthsarray = months;
  const { role, sessions, mentorRates, handleRatingChange, naviGate,findMentor } =
    useSessionsHistory(true);
  return (
    <>
      {sessions.length ? (
        <div className="activeSession-cont">
          {sessions.map((el) => {
            return (
              <div className="activeSession-cont__items" key={el.id}>
                <div className="activeSession-cont__items__dateCont">
                  <p className="activeSession-cont__items__dateCont__date">{
                  // `${new Date(el.datetime).getDate()} ${monthsarray[new Date(el.datetime).getMonth()]} ${new Date(el.datetime).getFullYear()}`
                  "Pending"
                  }</p>
                  {/* <p className="activeSession-cont__items__dateCont__duration">
                    {decideTime(el.duaration)}
                  </p> */}
                </div>
                <div className="activeSession-cont__items__info">
                  <div className="activeSession-cont__items__info__topicCont">
                    <p className="activeSession-cont__items__info__topicCont__topic">
                      Topic:
                    </p>
                    <p className="activeSession-cont__items__info__topicCont__subject">
                      {el.topic}
                    </p>
                  </div>
                  <div className="activeSession-cont__items__info__mentorCont">
                    <p className="activeSession-cont__items__info__mentorCont__mentor">
                      {role === "Student" ? "Mentor:" : "Student:"}
                    </p>
                    <p className="activeSession-cont__items__info__mentorCont__mentorsName">
                      {role === "Student" ? el.mentor : el.student}
                    </p>
                  </div>
                  {/* <button className="activeSession-cont__items__info__btn">
                    <p className="activeSession-cont__items__info__btn__text">
                      Start
                    </p>
                  </button> */}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="emptyActiveSessionCont">
          <div className="emptyActiveSessionCont__emptArea">
            <p className="emptyActiveSessionCont__emptArea__text">
              You don't have active sessions yet
            </p>
            {role === "Mentor" ? null : (
              <button
                className="emptySessionHistotyCont__emptArea__btn"
                onClick={() => {
                  naviGate("/scenario/0");
                }}
              >
                <p className="emptySessionHistotyCont__emptArea__btn__text">
                  Start test now
                </p>
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ActiveSessions;
