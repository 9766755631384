import React from "react";
import Marquee from "react-fast-marquee";
import { carouselData } from "../../constants/AppConstants";

const Carousel = () => {
  React.useEffect(() => {
    carouselData.forEach((career) => {
      const img = new Image();
      img.src = career.image; // Start downloading the image into the cache
    });
  }, []);

  return (
    <div
      style={{
        height: "300px",
        margin: "50px 0",
      }}
    >
      <Marquee velocity={25} minScale={0.7} pauseOnClick play={true}>
        {carouselData.map((career, index) => (
          <div
            key={index}
            style={{
              width: "222px",
              height: "279px",
              borderRadius: "10px",
              textAlign: "center",
              lineHeight: "50px",
              margin: "24px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              flexFlow: 1,
            }}
          >
            <img
              src={career.image}
              width="222"
              height="227"
              style={{
                width: "100%",
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
              alt={career.title || "Career image"}
            />
            <div
              style={{
                fontSize: "16px",
                lineHeight: "1.5",
                textAlign: "left",
                marginLeft: "12px",
                color: "#174273",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p>{career.description}</p>
            </div>
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default Carousel;
