import * as actionTypes from "../actionTypes";
const initialState = {
  industries: [],
  error: null,
};
const industryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_INDUSTRIES:
      return {
        ...state,
        industries: [],
      };
    case actionTypes.GET_INDUSTRIES_SUCCESS:
      return {
        ...state,
        industries: payload,
      };
    case actionTypes.GET_INDUSTRIES_FAIL:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};
export default industryReducer;
