import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import classnames from "classnames";

import story from "../../assets/story.png";

import "./Story.scss";

const Story = () => {
  const { pathname } = useLocation();
  return (
    <section className="Story">
      <div className="Story-container">
        <div className="Story-container-banner">
          <div className="Story-container-banner__content">
            <div className="Story-container-banner__content__text">
              <span>30%</span> of high schoolers chose to skip college simply
              because they did not know what they want to study
            </div>
            <div className="Story-container-banner__content__line" />
            <div className="Story-container-banner__content__text">
              <span>50%</span> of college dropouts left because they later
              realized that the career path they chose wasn’t right for them
            </div>
          </div>
          <div className="Story-container-banner__img">
            <img src={story} alt="" />
          </div>
        </div>
        <div className="Story-container-links">
          <div>
            <Link
              className={classnames("Story-container-links__item", {
                isActive:
                  pathname.includes("stories") && !pathname.includes("parents"),
              })}
              to={"students"}
            >
              Student's Stories
            </Link>
            <Link
              className={classnames("Story-container-links__item", {
                isActive: pathname.includes("parents"),
              })}
              to={"parents"}
            >
              Parent's Stories
            </Link>
          </div>
        </div>
        <Outlet />
      </div>
    </section>
  );
};

export default Story;
