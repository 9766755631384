import * as actionTypes from "../actionTypes";
const initialState = {
  error: null,
};
const profileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_PROFILE:
      return {
        ...state,
      };
    case actionTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: payload,
      };
    case actionTypes.GET_PROFILE_ERROR:
      return {
        ...state,
      };
    case actionTypes.SET_PROFILE:
      return {
        ...state,
      };
    case actionTypes.SET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: payload,
      };
    default:
      return state;
  }
};
export default profileReducer;
