import { useState } from "react";
import { useSelector } from "react-redux";
import { sessionState } from "../redux/selectors/sessionSelector";
import { authUser } from "../redux/selectors/authSelectors";
import { useNavigate } from "react-router-dom";

const useSessionsHistory = (active) => {
  const naviGate = useNavigate()
  const { sessions } = useSelector(sessionState)
  const storageUser = useSelector((state) => authUser(state));
  const { role } = storageUser || {};
  const mentors = useSelector((state)=> state.mentorReducer?.mentor?.results)
  const [mentorRates, setMentorRates] = useState(null)
  const findMentor = (mentorId)=>{
    return mentors.find((el)=>el.id===mentorId).first_name
  }
  const handleRatingChange = (mentorId, newRating) => {
    let newobj = {}
    if (mentorRates === null) {
      for (let i = 0; i < sessions.length; i++) {
        if (sessions[i].id === mentorId) {
          newobj[sessions[i].id] = [newRating]
        }
        else {
          newobj[sessions[i].id] = []
        }
      }
      setMentorRates(newobj)
    } else {
      setMentorRates({ ...mentorRates, [mentorId]: [newRating] })
    }
  }
  return {
    role,
    sessions: sessions.filter(session => active ? session.is_active : !session.is_active),
    mentorRates,
    handleRatingChange,
    naviGate,
    findMentor
  }
}

export default useSessionsHistory;