import * as actionTypes from "../actionTypes";

export const initGetRecommendation = () => ({
  type: actionTypes.INIT_GET_RECOMMENDATIONS,
});


export const getRecommendations = () => ({
  type: actionTypes.GET_RECOMMENDATIONS,
});

export const getRecommendationsSuccess = (payload) => ({
  type: actionTypes.GET_RECOMMENDATIONS_SUCCESS,
  payload,
});

export const getRecommendationsError = (error) => ({
  type: actionTypes.GET_RECOMMENDATIONS_ERROR,
  error,
});
