import * as actionTypes from "../actionTypes";
const initialState = {
  error: null,
  sessions: []
};
const sessionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_SESSIONS:
      return {
        ...state,
        sessions: []
      };
    case actionTypes.GET_SESSIONS_SUCCESS:
      return {
        ...state,
        sessions: payload?.results,
      };
    case actionTypes.GET_SESSIONS_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
};
export default sessionReducer;
