import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initSignIn } from "../redux/actions/authActions";
import {
  authIsAuthenticated,
  authIsLoading,
  authUser,
} from "../redux/selectors/authSelectors";
import { initSetProfile } from "../redux/actions";

const useSignIn = () => {
  const user = useSelector((state) => authUser(state));
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const errorFromBackend = useSelector((state) => state?.authReducer?.error);
  const [isErroModalOpened, setIsErrorModalOPened] = useState(false);

  //detect redirect from the url
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const isLoading = useSelector((state) => authIsLoading(state));
  const isAuthenticated = useSelector((state) => authIsAuthenticated(state));

  const handleLogin = (e, password) => {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e)) {
      setEmailError("Please enter a valid email address.");
      setShowEmailError(true);
      if (password === "") {
        setShowPasswordError(true);
        setPasswordError("Password field may not be blank.");
      } else {
        setShowPasswordError(false);
        setPasswordError("");
      }
    } else if (password === "") {
      setPasswordError("Password field may not be blank.");
      setShowPasswordError(true);
      setEmailError("");
      setShowEmailError(false);
    } else {
      setEmailError("");
      setPasswordError("");
      setShowEmailError(false);
      setShowPasswordError(false);
      dispatch(initSignIn({ email, password }));
      if (isAuthenticated) {
        if (redirect) {
          navigate(redirect);
        } else {
          navigate("/profile");
        }
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (redirect) {
        navigate(redirect);
      } else {
        navigate("/profile");
      }
    }
    if (isAuthenticated) {
      dispatch(initSetProfile(user));
    }
  }, [dispatch, isAuthenticated, navigate, redirect, user]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleLogin(email, password);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [email, password]);

  useEffect(() => {
    if (errorFromBackend) {
      setIsErrorModalOPened(true);
    }
  }, [errorFromBackend]);

  useEffect(() => {
    if (emailError) {
      setEmailError("");
      setShowEmailError(false);
    }
  }, [email]);

  useEffect(() => {
    if (passwordError) {
      setPasswordError(false);
      setShowPasswordError(false);
    }
  }, [password]);

  const closeErrorModal = () => {
    dispatch({
      type: "SIGNIN_FAIL",
      payload: {
        error: null,
      },
    });
    setIsErrorModalOPened(false);
    setEmail("");
    setPassword("");
  };

  const getErrorFromBackend = () => {
    let result = [];
    if (typeof errorFromBackend === "object" && errorFromBackend !== null) {
      for (let key in errorFromBackend) {
        result.push(
          <p
            key={Math.random()}
            style={{
              textAlign: "left",
            }}
          >{`${errorFromBackend[key]}`}</p>
        );
      }
    }
    return result;
  };
  return {
    email,
    setEmail,
    password,
    setPassword,
    handleLogin,
    isLoading,
    showEmailError,
    emailError,
    passwordError,
    showPasswordError,
    isErroModalOpened,
    closeErrorModal,
    getErrorFromBackend,
  };
};

export default useSignIn;
