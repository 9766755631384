import React from "react";
import "./LogOutModal.scss";

const LogOutModal = ({
  image,
  text,
  leftBtnText,
  rightBtnText,
  close,
  isOpened,
  label,
  setPhone,
  handleLogOut,
  isSaving,
  error
}) => {
  return isOpened ? (
    <div className="LogOutModal-cont" onClick={close}>
      <div
        className="LogOutModal-cont__content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="LogOutModal-cont__content__imgWrapper">
          <img
            src={image}
            alt="goodImage"
            className="LogOutModal-cont__content__imgWrapper__img"
          />
        </div>
        <p className="LogOutModal-cont__content__text">{text}</p>
        {label && <div className="LogOutModal-cont__content__text">
          <p>{label}</p>
          <input
            type="text"
            onChange={(e) => setPhone(e.target.value)}
          />
          {error && (
            <div className="LogOutModal-cont__content__error">
              <p>{error}</p>
            </div>
          )}
        </div>}
        <div className="LogOutModal-cont__content__btnWrapper">
          <button
            className="LogOutModal-cont__content__btnWrapper__btn1"
            onClick={close}
          >
            <p className="LogOutModal-cont__content__btnWrapper__btn1__leftBtnText">
              {leftBtnText}
            </p>
          </button>

          {<button disabled={isSaving} className={`LogOutModal-cont__content__btnWrapper__btn2 ${isSaving ? '__disabled' : ''}`} onClick={handleLogOut}>
            <p className="LogOutModal-cont__content__btnWrapper__btn2__text">
              {rightBtnText}
            </p>
          </button>}
        </div>
      </div>
    </div>
  ) : null;
};

export default LogOutModal;
