import { getIndustries, getIndustriesSuccess, getIndustriesError } from "../actions";
import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";

export function* getIndustriesSaga({ params }) {
  yield put(getIndustries());
  try {
    const response = yield call(routes.getIndustries, params);
    yield put(getIndustriesSuccess(response));
  } catch (error) {
    yield put(getIndustriesError(error));
  }
}

