import arrowDown from "../../../../assets/arrowDown.svg";
import useEditProfile from "../../../../hooks/useEditProfile";
import { CancelModal } from "../../../../modals";
import InterrogativeImage from "../../../../assets/interrogativeImage.png";
import "./EditProfile.scss";
import { useOutletContext } from "react-router-dom";
import classNames from "classnames";

const EditProfile = () => {
  const { user } = useOutletContext();
  const {
    fullName,
    isCloseModalOpened,
    age,
    email,
    phone,
    zipcode,
    address,
    isAgeListOpened,
    ages,
    role,
    addressRef,
    countryRef,
    country,
    state,
    city,
    aboutMe,
    handleAboutMe,
    closeCloseModal,
    openCloseModal,
    updateProfile,
    removeChanges,
    handleFullName,
    handleEmail,
    handlePhone,
    handleZipCode,
    handleAddress,
    openAgeList,
    handleAge,
    CloseAgeList,
    handleCountry,
    handleState,
    handleCity,
    careers,
    setCareers,
    certainty,
    setCertainty
  } = useEditProfile(user);
  return (
    <div className="EditProfileCont">
      <p className="EditProfileCont__title">Edit Profile Info</p>
      <div className="EditProfileCont__content">
        <div className="EditProfileCont__content__items">
          <p className="EditProfileCont__content__items__text">Full Name</p>
          <input
            type="text"
            placeholder={fullName ? fullName : "FullName"}
            className="EditProfileCont__content__items__inp"
            value={fullName}
            onChange={handleFullName}
          />
        </div>
        {/* {role !== 'Mentor' &&  */}
        <div className="EditProfileCont__content__items">
          <p className="EditProfileCont__content__items__text">Age</p>
          <div
            className="EditProfileCont__content__items__select"
            onClick={() => {
              if (isAgeListOpened) {
                CloseAgeList();
              } else {
                openAgeList();
              }
            }}
          >
            <p className="EditProfileCont__content__items__select__text">
              {age ? age : "Age"}
            </p>
            <div className="EditProfileCont__content__items__select__imgWrapper">
              <img
                src={arrowDown}
                alt="arrowDown"
                className="EditProfileCont__content__items__select__imgWrapper__img"
                style={
                  !isAgeListOpened
                    ? null
                    : {
                      transform: "rotate(180deg)",
                    }
                }
              />
            </div>
          </div>
          {isAgeListOpened ? (
            <div className="EditProfileCont__content__items__ageList">
              {ages.map((el, ind) => (
                <p
                  onClick={() => {
                    handleAge(el);
                  }}
                  className="EditProfileCont__content__items__ageList__age"
                  key={ind}
                >
                  {el}
                </p>
              ))}
            </div>
          ) : null}
        </div>
      </div>

      <div className="EditProfileCont__content">
        <div className="EditProfileCont__content__items">
          <p className="EditProfileCont__content__items__text">Email</p>
          <input
            type="text"
            placeholder={email ? email : "Email"}
            className="EditProfileCont__content__items__inp"
            onChange={handleEmail}
            value={email}
          />
        </div>
        <div className="EditProfileCont__content__items">
          <p className="EditProfileCont__content__items__text">Phone</p>
          <input
            type="number"
            placeholder={phone ? phone : "Phone"}
            className="EditProfileCont__content__items__inp"
            onChange={handlePhone}
            value={phone}
          />
        </div>
      </div>
      <div className="EditProfileCont__adressCont">
        <p className="EditProfileCont__adressCont__text">Address</p>
        <input
          type="text"
          placeholder={address ? address : "Address"}
          className="EditProfileCont__adressCont__inp"
          onChange={handleAddress}
          value={address}
          ref={addressRef}
        />
      </div>
      <div className="EditProfileCont__content">
        <div className="EditProfileCont__content__items">
          <p className="EditProfileCont__content__items__text">Country</p>
          <input
            type="text"
            value={country}
            onChange={handleCountry}
            className="EditProfileCont__content__items__inp"
            placeholder={country ? country : "Country"}
          />
          {/* <div className="EditProfileCont__content__items__select">
            <p className="EditProfileCont__content__items__select__text">
              Country
            </p>
            <div className="EditProfileCont__content__items__select__imgWrapper" >
              <img
                src={arrowDown}
                alt="arrowDown"
                className="EditProfileCont__content__items__select__imgWrapper__img"
              />
            </div>
          </div> */}
        </div>
        <div className="EditProfileCont__content__items">
          <p className="EditProfileCont__content__items__text">State</p>
          <input
            type="text"
            value={state}
            onChange={handleState}
            className="EditProfileCont__content__items__inp"
            placeholder={state ? state : "State"}
          />
          {/* <div className="EditProfileCont__content__items__select">
            <p className="EditProfileCont__content__items__select__text">
              State
            </p>
            <div className="EditProfileCont__content__items__select__imgWrapper">
              <img
                src={arrowDown}
                alt="arrowDown"
                className="EditProfileCont__content__items__select__imgWrapper__img"
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="EditProfileCont__content">
        <div className="EditProfileCont__content__items">
          <p className="EditProfileCont__content__items__text">City</p>
          <input
            type="text"
            placeholder={city ? city : "City"}
            className="EditProfileCont__content__items__inp"
            onChange={handleCity}
            value={city}
          />
          {/* <div className="EditProfileCont__content__items__select">
            <p className="EditProfileCont__content__items__select__text">
              City
            </p>
            <div className="EditProfileCont__content__items__select__imgWrapper">
              <img
                src={arrowDown}
                alt="arrowDown"
                className="EditProfileCont__content__items__select__imgWrapper__img"
              />
            </div>
          </div> */}
        </div>
        <div className="EditProfileCont__content__items">
          <p className="EditProfileCont__content__items__text">Zip Code</p>
          <input
            type="number"
            placeholder={zipcode ? zipcode : "Zip Code"}
            className="EditProfileCont__content__items__inp"
            onChange={handleZipCode}
            value={zipcode}
          />
        </div>
      </div>
      {
        role === 'Student' ? <>
          <div className="EditProfileCont__adressCont">
            <div className="SecondForm__tag">
              <p>What career(s) are you thinking about? (comma-separated)</p>
              <input value={careers} onChange={(e) => setCareers(e.target.value)} />
            </div>
          </div>
          <div className="EditProfileCont__adressCont">
            <div className="SecondForm__level">
              <p>Level of certainty of your future career path</p>
              <div className="SecondForm__level__cont">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                  <div
                    onClick={() => {
                      // setCertaintyError("");
                      setCertainty(item);
                    }}
                    key={item}
                    className="SecondForm__level__cont__grades"
                  >
                    <div
                      className={classNames({
                        activeCircle: certainty === item,
                      })}
                    />
                    <p>{item}</p>
                  </div>
                ))}
              </div>
              {/* {certaintyError && (
              <div className="FirstForm__error">
                <img src={Error} alt="" />
                <p>{certaintyError}</p>
              </div>
            )} */}
            </div>
          </div>


        </> :
          <div className="EditProfileCont__adressCont">
            <p className="EditProfileCont__adressCont__text">About me</p>
            <textarea
              type="text"
              rows="5"
              placeholder={"We'd love to know more about you! Please share a brief 'About Me' description in your profile. This information will be shared with students you engage with, helping them get to know you better and benefit more from your insights"}
              className="EditProfileCont__adressCont__inp"
              onChange={(e) => handleAboutMe(e.target.value)}
              value={aboutMe}
            />
          </div>
      }
      <div className={`EditProfileCont__btnCont ${role}`}>
        <button
          className="EditProfileCont__btnCont__btnCancel"
          onClick={openCloseModal}
        >
          <p className="EditProfileCont__btnCont__btnCancel__text">Cancel</p>
        </button>
        <button
          className="EditProfileCont__btnCont__btnSaveChange"
          onClick={updateProfile}
        >
          <p className="EditProfileCont__btnCont__btnSaveChange__text">
            Save Changes
          </p>
        </button>
      </div>
      <CancelModal
        image={InterrogativeImage}
        title="Are you sure you want to Cancel?"
        text="Your changes will not be saved."
        leftBtnText="No"
        rightBtnText="Yes"
        isOpened={isCloseModalOpened}
        close={closeCloseModal}
        removeChanges={removeChanges}
      />
    </div>
  );
};

export default EditProfile;
