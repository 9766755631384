import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { LogOutModal } from "../../../../modals";
import InterrogativeImage from "../../../../assets/interrogativeImage.png";
import "./Settings.scss";

const Settings = ({
  settings,
  handleSettingClick,
  name,
  surname,
  aiReportUrl,// AI Insights Report PDF url 
  handleLogOut,
  openInviteFriendModal,
  isLogOutModalOpened,
  openLogOutModal,
  closeLogoutModal,
  isMobile,
}) => {
  return (
    <>
      <div className="profileCont__settingsCont__textWrapper">
        <p className="profileCont__settingsCont__textWrapper__UserName">{`Hi ${name} !`}</p>
        <p className="profileCont__settingsCont__textWrapper__profileInfo">
          Profile Information
        </p>
      </div>
      <div className="Setting">
        {settings.map((el) => {
          if (el.department === "AI Insights Report") {
            return (
              <a
                target='_blank'
                key={el.id}
                rel="noopener noreferrer"
                href={aiReportUrl}
                className={
                  classnames("Setting__item", {
                      disableSetting: !aiReportUrl,
                    })
                }
              >
                <div className="Setting__item__imgWrapper">
                  <img
                    src={
                      !aiReportUrl
                          ? el.disableImage
                          : el.image
                    }
                    alt={el.image}
                    className="Setting__item__imgWrapper__img"
                  />
                </div>
                <p
                  className={
                    classnames("Setting__item__title", {
                        activeSettingText: el.isActive,
                      })
                  }
                >
                  {el.department}
                </p>
              </a>
            );
          } else if (el.department === "AI Career Buddy") {
            return (
              <div
                key={el.id}
                className={
                  classnames("Setting__item", {
                      disableSetting: el.isDisable,
                    })
                }
              >
                <div className="Setting__item__imgWrapper">
                  <img
                    src={el.image}
                    alt={el.image}
                    className="Setting__item__imgWrapper__img"
                  />
                </div>
                <p
                  className={
                    classnames("Setting__item__title", {
                        activeSettingText: el.isActive,
                      })
                  }
                >
                  {el.department}
                </p>
              </div>
            );
          } else if (el.department === "Invite a Friend") {
            return (
              <div
                key={el.id}
                className={
                  isMobile
                    ? "Setting__item"
                    : classnames("Setting__item", {
                      activeSetting: el.isActive,
                    })
                }
                onClick={() => {
                  // handleSettingClick(el.id, settings);
                  // the above line meaning is described in useProfile.js comment
                  openInviteFriendModal();
                }}
              >
                <div className="Setting__item__imgWrapper">
                  <img
                    src={
                      isMobile
                        ? el.image
                        : el.isActive
                          ? el.activeImage
                          : el.image
                    }
                    alt={el.image}
                    className="Setting__item__imgWrapper__img"
                  />
                </div>
                <p
                  className={
                    isMobile
                      ? "Setting__item__title"
                      : classnames("Setting__item__title", {
                        activeSettingText: el.isActive,
                      })
                  }
                >
                  {el.department}
                </p>
              </div>
            );
          } else {
            return (
              <Link
                to={el.route}
                key={el.id}
                className={
                  isMobile
                    ? "Setting__item"
                    : classnames("Setting__item", {
                      activeSetting: el.isActive,
                    })
                }
                onClick={() => {
                  handleSettingClick(el.id, settings);
                }}
              >
                <div className="Setting__item__imgWrapper">
                  <img
                    src={
                      isMobile
                        ? el.image
                        : el.isActive
                          ? el.activeImage
                          : el.image
                    }
                    alt={el.image}
                    className="Setting__item__imgWrapper__img"
                  />
                </div>
                <p
                  className={
                    isMobile
                      ? "Setting__item__title"
                      : classnames("Setting__item__title", {
                        activeSettingText: el.isActive,
                      })
                  }
                >
                  {el.department}
                </p>
              </Link>
            );
          }
        })}
      </div>
      <p
        onClick={openLogOutModal}
        className="profileCont__settingsCont__log-out"
      >
        Log Out
      </p>
      <LogOutModal
        image={InterrogativeImage}
        text="Are you sure you want to Log Out?"
        leftBtnText="No"
        rightBtnText="Yes"
        close={closeLogoutModal}
        isOpened={isLogOutModalOpened}
        handleLogOut={handleLogOut}
      />
    </>
  );
};

export default Settings;
