import { useState } from "react";
import { sessions } from "../constants/AppConstants";
import { authUser } from "../redux/selectors/authSelectors";
import { useSelector } from "react-redux";

const useMyFeedBack = () => {
  // const data = sessions.history;
  // const data = useSelector((state) => (state.FeedBackReducer))
  const data = useSelector((state) => (state?.FeedBackReducer?.feedbacks))
  const [activeBtnText, setActiveBtnText] = useState("Date high to low");
  const changeBtnText = (text) => {
    setActiveBtnText(text);
  };
  const storageUser = useSelector ((state) => authUser(state));
  const { role } = storageUser || {};
  return {
    activeBtnText,
    changeBtnText,
    data,
    role
  };
};

export default useMyFeedBack;
