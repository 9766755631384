import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";
import { setScenarios, setScenariosSuccess, setScenariosError } from "../actions";

export function* submitScenarioSaga ({ payload }) {
    yield put(setScenarios())
    try {
        const response = yield call(routes.setScenarios, payload);
        yield put(setScenariosSuccess(response));
      } catch (error) {
        yield put(setScenariosError(error));
      }
}