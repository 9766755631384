import React from "react";
import "./Loader.scss"; // Import the CSS file

const Loader = ({ color = "#00d8cc", size = 36, strokeWidth = 1 }) => {
  return (
    <div className="loader-container">
      <div className="loader">
        <div className="loading-square"></div>
        <div className="loading-square"></div>
        <div className="loading-square"></div>
        <div className="loading-square"></div>
      </div>
    </div>
  );
};

export default Loader;
