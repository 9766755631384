import * as actionTypes from "../actionTypes";

const initialState = {
  error: null,
};

const FeedBackReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_FEEDBACKS:
      return {
        ...state,
      };
    case actionTypes.GET_FEEDBACKS_SUCCESS:
      return {
        ...state,
        feedbacks: payload?.results,
      };
    case actionTypes.GET_FEEDBACKS_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default FeedBackReducer
