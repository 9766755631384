import React from "react";
import classNames from "classnames";
import "./BookModal.scss";
import { useState,useEffect } from "react";

const BookModal = ({
  number,
  title,
  image,
  text,
  isModalOpened,
  closeModal,
  handlescore,
  chosenItem,
  handleBook,
  handleBack,
  isContentType,
}) => {
  const [score, setScore] = useState(0)
  useEffect(() => {
    setScore(+chosenItem?.score || 0)
  }, [chosenItem?.score])
  const getRating = () => {
    return (
      <div className="BookModal__Content__sliderCont">
        <p className="BookModal__Content__sliderCont__text">
          Is this career path for you?
        </p>
        <div className="BookModal__Content__sliderCont__rateCont">
          <div className="BookModal__Content__sliderCont__rateCont__circles">
            {[1, 2, 3, 4, 5].map((el, ind) => (
              <div
                className="BookModal__Content__sliderCont__rateCont__circles__circle"
                key={ind}
                value={el}
              ></div>
            ))}
            <input
              type="range"
              className={classNames(
                "BookModal__Content__sliderCont__rateCont__circles__inp",
                {
                  "center-2": score === 1,
                  "center-1": score === 2,
                  "end-2": score === 4,
                  "end-1": score === 5,
                  slider_none: score === 0,
                }
              )}
              id="BookSlider"
              min={1}
              max={5}
              step={1}
              value={score}
              onClick={(e) => {
                if (e.target.value === "3" && chosenItem.score === null) {
                  handlescore(3);
                  setScore(3)
                }
              }}
              onChange={(e) => {
                setScore(Number(e.target.value))
                handlescore(Number(e.target.value));
              }}
            />
          </div>
          <div className="BookModal__Content__sliderCont__rateCont__num-cont">
            {[1, 2, 3, 4, 5].map((el, ind) => (
              <div
                className="BookModal__Content__sliderCont__rateCont__num-cont__num"
                key={ind}
              >
                {el}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  return isContentType ? (
    <div className="BookModal content" onClick={() => closeModal && closeModal()}>
      <div className="BookModal__Content" onClick={(e) => e.stopPropagation()}>
        {getRating()}
        <div className="BookModal__Content__btnCont">
          <button
            className="BookModal__Content__btnCont__cancelBtn"
            onClick={handleBack}
          >
            <p className="BookModal__Content__btnCont__cancelBtn__text">Back</p>
          </button>
          <button
            className="BookModal__Content__btnCont__BookBtn"
            onClick={() => handleBook()}
          >
            <p className="BookModal__Content__btnCont__BookBtn__text">
              Learn More
            </p>
          </button>
        </div>
      </div>
    </div>
  ) : isModalOpened ? (
    <div className="BookModal" onClick={() => closeModal && closeModal()}>
      <div className="BookModal__Content" onClick={(e) => e.stopPropagation()}>
        <div className="BookModal__Content__imgWrapper">
          <img
            src={image}
            alt="LongHand"
            className="BookModal__Content__imgWrapper__img"
          />
        </div>
        <div className="BookModal__Content__Description">
          <div className="BookModal__Content__Description__titleWrapper">
            <p className="BookModal__Content__Description__titleWrapper__num">
              {number}
            </p>
            <p className="BookModal__Content__Description__titleWrapper__title">
              {title}
            </p>
          </div>
          <p className="BookModal__Content__Description__text">{text}</p>
          <p className="BookModal__Content__Description__profile">
            More on your profile page
          </p>
        </div>
        {getRating()}
        <div className="BookModal__Content__btnCont">
          <button
            className="BookModal__Content__btnCont__cancelBtn"
            onClick={() => closeModal()}
          >
            <p className="BookModal__Content__btnCont__cancelBtn__text">
              Okay
            </p>
          </button>
          <button
            className="BookModal__Content__btnCont__BookBtn"
            onClick={() => handleBook()}
          >
            <p className="BookModal__Content__btnCont__BookBtn__text">
              Learn More
            </p>
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default BookModal;
