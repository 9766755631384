import "./Loader.scss"; // Import the CSS file
export default function LoaderSpin(){
    return<div className="dot-spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
}