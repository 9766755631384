import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";
import { getScenarios, getScenariosSuccess, getScenariosError } from "../actions";

export function* scenariosSaga ({ payload }) {
    yield put(getScenarios())
    try {
        const response = yield call(routes.getScenarios, payload);
        yield put(getScenariosSuccess(response));
      } catch (error) {
        yield put(getScenariosError(error));
      }
}