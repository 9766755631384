import * as actionTypes from "../actionTypes";

export const initGetFeedBack = () => ({
  type: actionTypes.INIT_GET_FEEDBACKS,
});

export const getFeedBack = () => ({
  type: actionTypes.GET_FEEDBACKS,
});


export const getFeedBackSuccess = (payload) => ({
    type: actionTypes.GET_FEEDBACKS_SUCCESS,
    payload,
  });

  export const getFeedBackError = (error) => ({
    type: actionTypes.GET_FEEDBACKS_ERROR,
    error,
  });