import React from "react";
import "./CustomSession.scss";
import useCustomSession from "../../../../../../hooks/useCustomSession";
import { Success } from "../../../../../../modals";
import SuccessImage from "../../../../../../assets/success.png";
import Error from "../../../../../../assets/error.svg";
import { useNavigate } from "react-router-dom";

const CustomSession = () => {
    const navigate = useNavigate();
    const {
        topic,
        setTopic,
        isSuccessModalOpened,
        isErrorModalOpened,
        closeSuccessModal,
        openSuccessModal,
        sendRequest
    } = useCustomSession();
    return (
        <>
            <div className="emptyCustomSessionCont">
                <div className="emptyCustomSessionCont__emptArea">
                    <p className="emptyCustomSessionCont__emptArea__text">
                        Have a unique request or need specific assistance? Feel free to submit a custom request here, and we'll do our best to accommodate your needs.
                    </p>
                    <input type="text" value={topic} onChange={(e) => setTopic(e.target.value)} className="emptyCustomSessionCont__emptArea__input" />
                    <button
                        className="emptyCustomSessionCont__emptArea__btn"
                        onClick={sendRequest}
                    >
                        <p className="emptySessionHistotyCont__emptArea__btn__text">
                            Submit
                        </p>
                    </button>
                </div>
            </div>
            <Success
                isOpened={isSuccessModalOpened}
                close={closeSuccessModal}
                title="success"
                image={SuccessImage}
            />

            <Success
                image={Error}
                title=""
                text={isErrorModalOpened}
                isOpened={isErrorModalOpened}
                close={() => {
                    navigate('/pricingplan')
                }}
            />
        </>
    );
};

export default CustomSession;
