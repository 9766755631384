import React from "react";
import leftCutSquare from "../../../../assets/cut-leftTriangle.svg";
import rightCutSquare from "../../../../assets/cut-rightTriangle.svg";
import Sundae from "../../../../assets/Sundae.svg";
import "./PricingPlanItems.scss";

const PricingPlanItems = ({
  handleClick,
  activeId,
  foodItems,
  isMobile,
  refProp,
  handleChoosePlan,
  handleTextToParent
}) => {
  const activeItem = foodItems.filter((item) => item?.id === activeId)[0];
  const getActiveItemDescription = (obj) => {
    let arr = obj?.description.split("\r\n");
    let res = [];
    if (obj?.id === 4 && obj?.title === "Milkshake") {
      for (let i = 1; i < arr.length; i++) {
        if (i === 4) {
          res.push(
            <li
              className="items__info__right__list-container__item"
              style={{ marginBottom: "8px" }}
              key={Math.random()}
            >
              {arr[i]}
              <div
                className="items__info__right__list-container__item__coming-soon"
                style={{ marginTop: "16px" }}
              >
                <img src={leftCutSquare || Sundae} alt="leftCutSquare" />
                <p className="items__info__right__list-container__item__coming-soon__text">
                  COMING SOON
                </p>
                <img src={rightCutSquare || Sundae} alt="rightCutSquare" />
              </div>
            </li>
          );
        } else if (i === arr.length - 2) {
          res.push(
            <li
              className="items__info__right__list-container__item"
              key={Math.random()}
            >
              {arr[i]}
              <p className="items__info__right__list-container__item__more-text">
                {!isMobile
                  ? arr[i + 1]
                  : arr[i + 1].split(". ").slice(0, -2).join(" ")}
              </p>
            </li>
          );
          i += 1;
        } else {
          res.push(
            <li
              className="items__info__right__list-container__item"
              key={Math.random()}
            >
              {arr[i]}
            </li>
          );
        }
      }
    } else {
      for (let i = 1; i < arr?.length; i++) {
        if (i === 3) {
          res.push(
            <li
              className="items__info__right__list-container__item"
              style={{ marginBottom: "8px" }}
              key={Math.random()}
            >
              {arr[i]}
              <div className="items__info__right__list-container__item__hundreed-percent">
                {arr[i + 1]}
              </div>
              <div className="items__info__right__list-container__item__coming-soon">
                <img src={leftCutSquare || Sundae} alt="leftCutSquare" />
                <p className="items__info__right__list-container__item__coming-soon__text">
                  COMING SOON
                </p>
                <img src={rightCutSquare || Sundae} alt="rightCutSquare" />
              </div>
            </li>
          );
          i += 1;
        } else {
          res.push(
            <li
              className="items__info__right__list-container__item"
              key={Math.random()}
            >
              {arr[i]}
            </li>
          );
        }
      }
    }
    return res;
  };
  let activeItemDescription = getActiveItemDescription(activeItem);

  const renderFoodItems = (arr) => {
    let res = arr.map((el) => {
      if (el?.id === activeId) {
        return (
          <React.Fragment key={el.id}>
            <div
              className="items__info__left__item isActiveItem"
              key={el.id}
              onClick={(e) => handleClick(e, el?.id, foodItems)}
              id={el.id}
              ref={refProp}
            >
              <div className="items__info__left__item__img-wrapper">
                <img
                  src={el.image || Sundae}
                  alt={el.title}
                  className="items__info__left__item__img-wrapper__img"
                />
              </div>
              <div className="items__info__left__item__news">
                <p className="items__info__left__item__news__title">
                  {el.title}
                </p>
                {el.price ? (
                  <p className="items__info__left__item__news__Price">
                    {el.price}
                    <span className="items__info__left__item__news__Price__duration">
                      {el.duration === "" ? null : `/${el?.duration}`}
                    </span>
                  </p>
                ) : null}
              </div>
            </div>
            {isMobile ? (
              <div className="items__info__right">
                <p className="items__info__right__title">
                  {activeItem.description.split("\r\n")[0]}
                </p>
                <ul className="items__info__right__list-container">
                  {activeItemDescription}
                </ul>
                <div className="items__info__right__btn-container">
                  <button className="items__info__right__btn-container__choose" onClick={handleChoosePlan}>
                    <p className="choose-text" >
                      Choose
                    </p>
                  </button>
                  <button className="items__info__right__btn-container__serve-pass" onClick={handleTextToParent}>
                    <p className="items__info__right__btn-container__serve-pass__serveText">
                      Text to Parent
                    </p>
                  </button>
                  {/* <button className="items__info__right__btn-container__serve-pass" onClick={handleChoosePlan}>
                    <p className="items__info__right__btn-container__serve-pass__serveText">
                      A Soft Serve Pass
                    </p>
                  </button> */}
                </div>
              </div>
            ) : null}
          </React.Fragment>
        );
      } else {
        return (
          <div
            className="items__info__left__item"
            key={el.id}
            onClick={(e) => {
              handleClick(e, el.id, foodItems);
            }}
            id={el.id}
          >
            <div className="items__info__left__item__img-wrapper">
              <img
                src={el.image || Sundae}
                alt={el.title}
                className="items__info__left__item__img-wrapper__img"
              />
            </div>
            <div className="items__info__left__item__news">
              <p className="items__info__left__item__news__title">{el.title}</p>
              {el.price ? (<p className="items__info__left__item__news__Price">
                {el.price}
                <span className="items__info__left__item__news__Price__duration">
                  {el.duration === "" ? null : `/${el.duration}`}
                </span>
              </p>) : null}
            </div>
          </div>
        );
      }
    });
    return res;
  };
  let items = renderFoodItems(foodItems);

  return (
    <div className="items">
      <div className="items__info">
        <div className="items__info__left">{items}</div>
        {!isMobile ? (
          <div className="items__info__right">
            <p className="items__info__right__title">
              {activeItem.description.split("\r\n")[0]}
            </p>
            <ul className="items__info__right__list-container">
              {activeItemDescription}
            </ul>
            <div className="items__info__right__btn-container">
              <button className="items__info__right__btn-container__choose" onClick={handleChoosePlan}>
                <p className="choose-text" >
                  Choose
                </p>
              </button>
              <button className="items__info__right__btn-container__serve-pass" onClick={handleTextToParent}>
                <p
                  className="items__info__right__btn-container__serve-pass__serveText"

                >
                  Text to Parent
                </p>
              </button>
              {/* <button className="items__info__right__btn-container__serve-pass" onClick={handleChoosePlan}>
                <p
                  className="items__info__right__btn-container__serve-pass__serveText"
                  
                >
                  A Soft Serve Pass
                </p>
              </button> */}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PricingPlanItems;
