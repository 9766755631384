import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";
import {
  getQuotes,
  getQuotesError,
  getQuotesSuccess,
} from "../actions";

export function* getQuotesSaga(params) {
  yield put(getQuotes());
  try {
    const response = yield call(routes.getQuotess, params.data);
    yield put(getQuotesSuccess(response));
  } catch (error) {
    yield put(getQuotesError(error));
  }
}