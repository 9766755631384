import { useEffect, useRef, useState } from "react";
import useWindowWidth from "./useWindowWidth";
import { useDispatch, useSelector } from "react-redux";
import { initGetProfile, initPricingPlan, initSetProfile } from "../redux/actions";
import { useNavigate, useParams } from "react-router-dom";
import axiosClient from "../common/api/request";


const usePricingPlan = () => {
  const dispatch = useDispatch();
  const [activeId, setActiveId] = useState(0);
  const pricingPlans = useSelector(state => state.pricingPlanReducer.plans);
  const { profile } = useSelector(state => state.profileReducer);
  const isSaved = useSelector(state => state.pricingPlanReducer.isSaved);
  const { isMobile, width } = useWindowWidth()
  const [popupVisiblity, setPopupVisibility] = useState(false);
  const clickedElementRef = useRef(null);
  const [firstClickedelement, setFisrstClickedElement] = useState(false)
  const [isConfirmPopupVisible, setConfirmPopupVisibility] = useState(false)
  const [isContactPopupVisible, setContactPopupVisibility] = useState(false)
  const [isFailedPopupVisible, setFailedPopupVisibility] = useState(false)
  const [sendToParentSuccess, sendToParentSuccessVisibility] = useState(false)
  const { responseType } = useParams();
  const [phone, setPhone] = useState('')
  const [error, setError] = useState(null)
  const [isSaving, setIsSaving] = useState(false);
  const [parentPopupVisibility, setParentPopupVisibility] = useState(false);
  const navigate = useNavigate();
  const handleClick = (e, id, arr) => {
    setActiveId(id);
    setFisrstClickedElement(true)
    const clickedElement = e.target;
    clickedElementRef.current = clickedElement;
  };

  function handlePaymentRedirect() {
    setConfirmPopupVisibility(false);
    axiosClient
    .post("payment_link/", {
      pricing_plan: activeId
    })
    .then((data) => {
      window.location.href = data?.payment_link;
    })
    .catch(() => {
    });
  }

  function handleParentPaymentRedirect(phone) {
    setIsSaving(true)
    setError(null);
    axiosClient
    .post("parent_payment_link/", {
      pricing_plan: activeId,
      phone
    })
    .then((data) => {
      setIsSaving(false);
      setParentPopupVisibility(false);
      sendToParentSuccessVisibility(true);
      // window.location.href = data?.payment_link;
    })
    .catch((e) => {
      setIsSaving(false);
      setError('Something went wrong')
    });
  }


  useEffect(() => {
    switch (responseType) {
      case "success":
        setContactPopupVisibility(true)
        break;
      case "cancel":
        setFailedPopupVisibility(true)
        break;
      default:
        setContactPopupVisibility(false);
        setFailedPopupVisibility(false)
        break;
    }
  }, [responseType])

  useEffect(() => {
    isSaved && setPopupVisibility(true)
  }, [isSaved])

  useEffect(() => {
    if (profile && pricingPlans.length) {
      profile.pricing_plan ? setActiveId(profile.pricing_plan) : setActiveId(pricingPlans[0].id)
    }
  }, [profile, pricingPlans])

  function closePopup() {
    setPopupVisibility(false);
  }

  const handleChoosePlan = () => {
    setConfirmPopupVisibility(true);
  }

  const handleTextToParent = () => {
    setParentPopupVisibility(true);

  }

  function handleChoosePlanConfirm() {
    // dispatch(initSetProfile({ pricing_plan: activeId }))
   
    setConfirmPopupVisibility(false);
    setContactPopupVisibility(false);
    setFailedPopupVisibility(false);
    navigate('/pricingplan')
  }

  useEffect(() => {
    dispatch(initPricingPlan())
    dispatch(initGetProfile())
  }, [])

  useEffect(() => {
    if (isMobile && firstClickedelement) {
      clickedElementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [clickedElementRef.current])
  return {
    popupVisiblity,
    setPopupVisibility,
    pricingPlans,
    activeId,
    clickedElementRef,
    isMobile,
    width,
    isConfirmPopupVisible,
    isContactPopupVisible,
    responseType,
    isFailedPopupVisible,
    parentPopupVisibility,
    phone,
    error,
    isSaving,
    sendToParentSuccess,
    sendToParentSuccessVisibility,
    setPhone,
    setContactPopupVisibility,
    setConfirmPopupVisibility,
    handleChoosePlanConfirm,
    setParentPopupVisibility,
    handleChoosePlan,
    handleTextToParent,
    handlePaymentRedirect,
    handleParentPaymentRedirect,
    handleClick
  }
}

export default usePricingPlan