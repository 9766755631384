import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const useSignUpStudentFirst = (setCompleteAddress, addZipCode) => {
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      { types: ["geocode"] }
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      setAddress(place.formatted_address);

      const addressComponents = place.address_components;
      for (let i = 0; i < addressComponents.length; i++) {
        const addressType = addressComponents[i].types[0];
        if (addressType === "locality") {
          setCity(addressComponents[i].long_name);
        } else if (addressType === "postal_code") {
          setZipCode(addressComponents[i].long_name);
        } else if (addressType === "administrative_area_level_1") {
          setState(addressComponents[i].long_name);
        } else if (addressType === "country") {
        }
      }
    });
  }, []);

  useEffect(() => {
    addZipCode(zipCode);
    setCompleteAddress(address);
  }, [zipCode, addZipCode, setCompleteAddress, address]);

  return {
    city,
    state,
    inputRef,
    address,
    handleBack,
    zipCode,
    setAddress,
  };
};

export default useSignUpStudentFirst;
