import { initGetQuotes } from "../../../../redux/actions";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Apostrophe from "../../../../assets/Apostrophe.svg";
import "./Feedback.scss";

const Feedback = React.memo(() => {
  const [content, setContent] = useState([]);
  const dispatch = useDispatch();
  const quotes = useSelector((state) => state.quotesReducer.quotes);
  useEffect(() => {
    dispatch(initGetQuotes({per_page:3}));
  }, []);
  useEffect(() => {
    if (quotes && quotes.length > 0) {
      // Add isOpen property to each quote if needed
      const quotesWithIsOpen = quotes.map((quote, key) => ({
        ...quote,
        isOpen: key === 0,
      }));
      setContent(quotesWithIsOpen);
    }
  }, [quotes]);
  const handleItemClick = useCallback((id) => {
    setContent((prevContent) =>
      prevContent.map((item) => ({
        ...item,
        isOpen: item.id === id,
      }))
    );
  }, []);
  if (!content.length) return null;
  return (
    <section className="Feedback">
      <p>See how we’re making a difference</p>
      <div className="Feedback-container">
        {content.map(({ id, image, name, isOpen, title, review }) => (
          <div
            onClick={() => handleItemClick(id)}
            key={id}
            className={`item${isOpen ? " active" : ""}`}
          >
            <div className="item-author">
              <div className="item-author__image-container">
                <img src={image} alt={name} />
              </div>
              <p>{name}</p>
            </div>
            {isOpen && (
              <div className="item-content">
                <div className="item-content__title">
                  <p>{title}</p>
                  <img src={Apostrophe} alt="Apostrophe" />
                </div>
                <p className="item-content__text">{review}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
});

export default Feedback;
