import classNames from "classnames";

const Answers = ({answer, setAnswer}) => {
    return (
        [1, 2, 3, 4, 5].map((item) => (
            <div className={classNames({
                answered: item === answer
            })} onClick={() => {
                setAnswer(item)
            }} key={item}>{item}</div>
        ))
    )
}

export default Answers;
