import React from "react";
import { useDrop } from "react-dnd";
import tagInfo from "../../../../modals";
import "./Questions.scss";
const ConeCont = ({ addTagToIceCone, data, index, handleRemoveItem }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "tag",
    drop: (item, monitor) => {
      return addTagToIceCone(item.id, monitor);
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
      };
    },
  }));
  return (
    <div
      className={`scenario2-Questions__imgWrapper__inner__ratedContainer__Rate`}
      ref={drop}
    >
      {data?.map((item, ind) => {
        return (
          <div onClick={() => handleRemoveItem(item.id, index)} key={item.id} className="scenario2-Questions__imgWrapper__inner__ratedContainer__Rate__coneText">
            <p>
              {item.text}
            </p>
          </div>
        );
      })}
    </div>
  );
};
export default ConeCont;
