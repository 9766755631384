import * as actionTypes from "../actionTypes";

export const initGetProfile = () => ({
  type: actionTypes.INIT_GET_PROFILE,
});

export const initSetProfile = (results) => ({
  type: actionTypes.INIT_SET_PROFILE,
  payload:results
});

export const getProfile = () => ({
  type: actionTypes.GET_PROFILE,
});

export const getProfileSuccess = (payload) => ({
  type: actionTypes.GET_PROFILE_SUCCESS,
  payload,
});

export const getProfileError = (error) => ({
  type: actionTypes.GET_PROFILE_ERROR,
  error,
});

export const setPrfoile = () => ({
  type: actionTypes.SET_PROFILE,
});

export const setPrfoileSuccess = (payload) => {
  return {
    type: actionTypes.SET_PROFILE_SUCCESS,
    payload,
  }
};

export const setPrfoileError = (error) => ({
  type: actionTypes.SET_PROFILE_ERROR,
  error,
});
