import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";
import { setPricingPlan, setPricingPlanSuccess, setPricingPlanError } from "../actions";

export function* setPricingPlanSaga({ params }) {
  yield put(setPricingPlan())
  try {
    const response = yield call(routes.setPricingPlan, params);
    yield put(setPricingPlanSuccess(response));
  } catch (error) {
    yield put(setPricingPlanError(error));
  }
}