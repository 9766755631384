import React, { useEffect } from "react";
import BaseModal from "../BaseModal";
import Loader from "../../components/Loader";
import "./RecommendationRetrievalModal.scss";

const RecommendationRetrievalModal = ({ isOpen, onClose }) => {
  useEffect(() => {
    console.log("isOpen", isOpen);
  }, [isOpen]);

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <div className="recommendations-modal">
        <h2>Almost there!</h2>
        <Loader />
        <p>
          It looks like our engine is still working on finding your perfect career match! 
          We’ll notify you via email once it’s ready, but feel free to check back later for your recommendations in your profile. 
          Thanks for your patience! 
        </p>
      </div>
    </BaseModal>
  );
};

export default RecommendationRetrievalModal;
