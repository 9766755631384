import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";
import { signIn, signInError, signInSuccess } from "../actions";

export function* signInSaga({ payload }) {
  yield put(signIn());
  try {
    const response = yield call(routes.auth, payload);
    yield put(signInSuccess(response));
  } catch (error) {
    yield put(signInError(error));
  }
}
