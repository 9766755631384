import React from "react";
import arrowLeft from "../../../../assets/grayArrowLeft.svg";
import "./Career.scss";
import classNames from "classnames";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import useCareer from "../../../../hooks/useCareer";
import { BookModal, CancelModal, Success } from "../../../../modals";
import MoreRecommendations from "../../../../modals/MoreRecommendations";
import { useState } from "react";
import VideoPlayer from "../../../../components/VideoPlayer";
{/* <video
                src={state?.unique_title.resource}
                width="200px"
                className="CareerCont__decription__contentImg"
                controls
              /> */}
const Career = () => {
  const {
    navigateBack,
    state,
    profile,
    isConfirmModal,
    modalInfo,
    chosenItem,
    handleBack,
    handlescore,
    handleClose,
    handleBookValidation,
    handleConfirm,
  } = useCareer();
  const [isMoreRecommendations, setIsMoreRecommendations] = useState(false)
  const { isMobile } = useWindowWidth();
  const handleRecommend = (val = null) => {
    setIsMoreRecommendations(!isMoreRecommendations)
    handleClose(val)
    if (!val) {
      handleBookValidation(state.id, false)     
    }    
  }
  return (         
    <div className="CareerCont">
      <div className="infoCont">
        <div className="CareerCont__header">
          <div
            onClick={() => {
              navigateBack();
            }}
            className={classNames("CareerCont__header__iconWrapper", {
              hide: isMobile,
            })}
          >
            <img
              src={arrowLeft}
              alt=""
              className="CareerCont__header__iconWrapper__icon"
            />
          </div>
          <div className="CareerCont__header__textCont">
            <p
              className={classNames("CareerCont__header__textCont__Profecy", {
                hide: isMobile,
              })}
            >
              {state?.text}
            </p>
            <p className="CareerCont__header__textCont__mentor">
              <span className="CareerCont__header__textCont__mentor__key">
                Mentor:{" "}
              </span>
              {state?.first_name}
            </p>
          </div>
        </div>
        <div className="CareerCont__decription__content">
          <p className="CareerCont__decription">{state?.description_long ? state?.description_long?.split('  ').map((text, i) => (
              <React.Fragment key={i}>
                {text}
                {i < state.description_long.split('  ').length - 1 && <><br /> <br /></>}
              </React.Fragment>
            )) : ''}</p>
          {state?.unique_title?.resource && (
            state?.unique_title?.resource_type === 'image' ? (
              <img
                src={state?.unique_title.resource}
                width="200px"
                className="CareerCont__decription__contentImg"
                alt="Resource"
              />
            ) : (
              <VideoPlayer
            videoSrc={state?.unique_title.resource}
          />
            )
          )}
        </div>
      </div>
      <BookModal
        handlescore={handlescore}
        chosenItem={state}
        handleBook={handleRecommend}
        text={state?.description}
        isContentType={true}
        handleBack={() => handleBack(state.id)}
      />
      <MoreRecommendations
        title="Choose your way to learn more."
        isOpened={isMoreRecommendations}
        handleRecommend={handleRecommend}
        onClose={() => handleRecommend(null)}
        aiReportUrl={profile?.ai_report}
        careerOutlookVal={state?.unique_title}
      />
      <CancelModal
        image={modalInfo[isConfirmModal]?.image}
        title={modalInfo[isConfirmModal]?.title}
        text={modalInfo[isConfirmModal]?.text}
        text2={modalInfo[isConfirmModal]?.text2}
        isOpened={isConfirmModal}
        removeChanges={handleConfirm}
        leftBtnText='Back'
        rightBtnText='Okay'
        close={() => handleClose(null)}
      />
    </div>
  );
};

export default Career;
