import React from "react";

import pencil from "../../../../assets/pencil.svg";

import "./ParentsStory.scss";
import useStory from "../../../../hooks/useStory";
import Envelope from "../../../../assets/InviteFriendEnvelope.png";
import { InviteFriendModal } from "../../../../modals";

const ParentsStory = () => {
  const { closeModal, openModal, isModalOpened } = useStory();
  return (
    <section className="Parents">
      <div className="Parents-row">
        <div className="Parents-row__mock__1" />
        <div className="Parents-row__item">
          <p>“It’s a video session with a professional so it can be done from the
            comfort of home or at school. They don’t have to travel somewhere. It
            could reach more students who don’t have reliable transportation.”</p>
        </div>
        <div className="Parents-row__item">
          <p>Allows my child [student] to pinpoint careers that he would be
            interested in as well as being able to contact professionals in those
            fields.</p>
        </div>
        <div className="Parents-row__mock__2" />
      </div>
      <div className="Parents-row">
        <div className="Parents-row__mock__3" />
        <div className="Parents-row__item">
          <p>“Provides them [students] with confidence and guidance. Helps them
            through the process so it doesn’t overwhelm them”</p>
        </div>
        <div className="Parents-row__item">
         <p>
           “I like that it tries to gives the student confidence to go into the
           workplace”
         </p>
        </div>
        <div className="Parents-row__add" onClick={openModal}>
          <div className="Parents-row__add__img">
            <img src={pencil} alt="" />
          </div>
          <p className="Parents-row__add__text">Share your story with us!</p>
        </div>
        <div className="Parents-row__mock__4" />
      </div>
      <InviteFriendModal
      isOpened={isModalOpened}
      closeModal={closeModal}
      title="Contact us"
      text="Please feel free to leave us a detailed message below, and we'll make sure to get back to you promptly. You can also reach us by phone at (407) 205-7872 or email us directly at info@career-scoops.com"
      leftBtnText="Cancel"
      rightBtnText="Send"
      img={Envelope}
      />
    </section>
  );
};

export default ParentsStory;
