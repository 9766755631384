import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";
import {
  getFeedBack,
  getFeedBackError,
  getFeedBackSuccess,
} from "../actions";

export function* getFeedBackSaga({ params }) {
  yield put(getFeedBack());
  try {
    const response = yield call(routes.getFeedBacks, params);
    yield put(getFeedBackSuccess(response));
  } catch (error) {
    yield put(getFeedBackError(error));
  }
}
