import {
  removeJWTLocalStorage,
  removeREFRESHLocalStorage,
  removeUSERFromLocalStorage,
  setJWTInLocalStorage,
  setREFRESHInLocalStorage,
  setUSERInLocalStorage,
} from "../common/localStorage";

export default class AuthService {
  constructor(initialState) {
    this.state = initialState;
  }

  signInSuccess(state, { access, refresh, user }) {
    setJWTInLocalStorage(access);
    setREFRESHInLocalStorage(refresh);
    setUSERInLocalStorage(user);
    return {
      ...state,
      isAuthenticated: true,
      access,
      user,
      refresh,
      isLoading: false,
      error:null
    };
  }
  signInFail(state,payload) {
    return {
      ...state,
      isAuthenticated: false,
      isLoading: false,
      error:payload.error
    };
  }
  signOut(state) {
    removeUSERFromLocalStorage();
    removeJWTLocalStorage();
    removeREFRESHLocalStorage();
    return {
      ...state,
      access: "",
      refresh: "",
      isAuthenticated: false,
      isLoading: false,
      user: null,
    };
  }
}
