import React, { useState } from "react";
import "./SkillModal.scss";
import classNames from "classnames";
const SkillModal = ({ title, description, isOpened, close, sortTag }) => {
  const [rate, setRate] = useState(null);
  return isOpened ? (
    <div
      className="SkillModal"
      onClick={() => {
        close();
        setRate(null);
      }}
    >
      <div className="SkillModal__content" onClick={(e) => e.stopPropagation()}>
        <p className="SkillModal__content__title">{title}</p>
        <p className="SkillModal__content__description">{description}</p>
        <div className="SkillModal__content__ratesCont">
          <div className="SkillModal__content__ratesCont__ratingSystem">
            {[1, 2, 3, 4, 5].map((el, ind, arr) => (
              <div
                className={classNames(
                  "SkillModal__content__ratesCont__ratingSystem__rate",
                  {
                    first: ind === 0,
                    end: ind === arr.length - 1,
                    greenBorderSkill: rate === el,
                  },
                )}
                key={ind}
                onClick={() => {
                  setRate(el);
                }}
              >
                <p className="SkillModal__content__ratesCont__ratingSystem__rate__text">
                  {el}
                </p>
              </div>
            ))}
          </div>
          <div className="SkillModal__content__ratesCont__info">
            <p className="SkillModal__content__ratesCont__info__textLeft">
              I am great at it
            </p>
            <p className="SkillModal__content__ratesCont__info__textRight">
              I am not good at it
            </p>
          </div>
        </div>
        <div className="SkillModal__content__btnsWrapper">
          <button
            className="SkillModal__content__btnsWrapper__cancel"
            onClick={() => {
              close();
              setRate(null);
            }}
          >
            <p className="SkillModal__content__btnsWrapper__cancel__text">
              Cancel
            </p>
          </button>
          <button
            className="SkillModal__content__btnsWrapper__save"
            onClick={() => {
              if (rate === null) {
                close();
              } else {
                sortTag(rate);
                setRate(null);
                close();
              }
            }}
          >
            <p className="SkillModal__content__btnsWrapper__save__text">Save</p>
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default SkillModal;
