import { useNavigate } from "react-router-dom";
import useScenarioQuestions from "./useScenarioQuestions";
import { useEffect, useState } from "react";

const useScenarioFooter = () => {
  const navigate = useNavigate();
  const { scenarioId, mappingArray } = useScenarioQuestions();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(false)
  }, [scenarioId])

  const handleBack = () => {
    navigate(-1);
  };

  return {
    handleBack,
    scenarioId,
    disabled,
    setDisabled,
    mappingArray
  };
};

export default useScenarioFooter;
