import * as actionTypes from "../actionTypes";
const initialState = {
  plans: [],
  error: null,
  isSaved: false
};
const pricingPlanReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.INIT_GET_PRICING_PLAN:
      return {
        ...state,
        isSaved: false
      };
    case actionTypes.GET_PLANS:
      return {
        ...state,
      };
    case actionTypes.GET_PLANS_SUCCESS:
      return {
        ...state,
        plans: payload,
      };
    case actionTypes.GET_PLAN_ERROR:
      return {
        ...state,
        error: payload,
      };
    case actionTypes.SET_PLAN:
      return {
        ...state,
        isSaved: false
      };
    case actionTypes.SET_PLAN_SUCCESS:
      return {
        ...state,
        isSaved: true
      };
    case actionTypes.SET_PLAN_ERROR:
      return {
        ...state,
        error: payload,
        isSaved: true
      };
    default:
      return state;
  }
};
export default pricingPlanReducer;
