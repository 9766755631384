import React, { useState } from "react";
import { Link } from "react-router-dom";
import Envelope from "../../assets/InviteFriendEnvelope.png";

import Student from "../../assets/Student.png";
import Mentor from "../../assets/Mentor.png";

import "./SignUp.scss";
import { InviteFriendModal } from "../../modals";

const SignUp = () => {
  const [isModalOpened, setIsmoadlOpened] = useState(false);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const openModal = (bookModaltitle, bookModalText) => {
    setTitle(bookModaltitle);
    setText(bookModalText);
    setIsmoadlOpened(true);
  };
  const closeModal = () => {
    setIsmoadlOpened(false);
  };
  return (
    <section className="Signup">
      <h3>
        Welcome to <span>Career Scoops</span> sign up! We can't wait to meet
        you. First off, which one are you?
      </h3>
      <div className="Signup-container">
        <div className="Signup-container-item">
          <div style={{ background: "#0040FF" }}>
            <img src={Mentor} alt="student" />
          </div>
          <Link to="/new/student" className="Signup-container-btn">
            Career Explorer
          </Link>
        </div>
        <div className="Signup-container-item">
          <div style={{ background: "#FFBE00" }}>
            <img src={Student} alt="student" />
          </div>
          <Link to="mentor" className="Signup-container-btn">
            Community Partner
          </Link>
        </div>
      </div>
      <p>
        Please contact us if you're a representative of an{" "}
        <span
          onClick={() => {
            openModal(
              "Contact us",
              "Please feel free to leave us a detailed message below, and we'll make sure to get back to you promptly. You can also reach us by phone at (407) 205-7872 or email us directly at info@career-scoops.com",
            );
          }}
        >
          Organization
        </span>
      </p>
      <InviteFriendModal
        isOpened={isModalOpened}
        closeModal={closeModal}
        text={text}
        title={title}
        leftBtnText="Cancel"
        rightBtnText="Send"
        img={Envelope}
        from="Home"
      />
    </section>
  );
};

export default SignUp;
