import React from "react";
import StatusBar from "../../../../components/StatusBar";
import Marni2 from "../../../../assets/Marni2.png";
import Marni from "../../../../assets/Marni.png";
import ScenarioFooter from "../../../../components/ScenarioFooter/ScenarioFooter";
import Answers from "./Answers";
import useQuestion6 from "../../../../hooks/useQuestion6";
import useWindowWidth from "../../../../hooks/useWindowWidth";

const Question6 = ({ data, total, progress }) => {
  const { answers, setAnswer } = useQuestion6(data);
  const { isMobile, isSmallDesktop } = useWindowWidth();
  return (
    <section className="Question">
      <div className="Question-cont">
        {!isSmallDesktop && <div className="Question-cont__img">
          <img src={Marni2} alt="" />
        </div>}
        <div className="Question-cont__content">
          <h1>SCENARIO 1</h1>
          <p className="Question-cont__content__text">
            {data[0].question.split(': ')[0]}?
          </p>
          <StatusBar activeIndex={+progress} totalAmount={total} />
          <div className="Question-cont__content__select-cont">
            {data.map((q, i) => (
              <div key={q.id} className="item">
                <p className="item-title">{q.question.split(': ')[1]}</p>
                <div className="item-cont">
                  <Answers answer={answers[i].value} setAnswer={(value) => setAnswer(q.id, value)} />
                </div>
              </div>
            ))}
          </div>
        </div>
        {!isSmallDesktop && <div className="Question-cont__img">
          <img src={Marni} alt="" />
        </div>}
      </div>
      <ScenarioFooter
        variation="question"
        buttons={{ next: { disabled: answers.some(item => !item.value) ? true : false, text: 'NEXT SCENARIO' } }}
        where={`/scenario/2`}
      />
    </section>
  );
};

export default Question6;
