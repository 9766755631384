import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";
import { getPricingPlan, getPricingPlanSuccess, getPricingPlanError } from "../actions";

export function* getPricingPlanSaga ({ payload }) {
    yield put(getPricingPlan())
    try {
        const response = yield call(routes.getPricingPlan, payload);
        yield put(getPricingPlanSuccess(response));
      } catch (error) {
        yield put(getPricingPlanError(error));
      }
}