import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";
import { getResults, getResultsSuccess, getResultsError } from "../actions";

export function* getResultsSaga ({ payload }) {
    yield put(getResults())
    try {
        const response = yield call(routes.getResults, payload);
        yield put(getResultsSuccess(response));
      } catch (error) {
        yield put(getResultsError(error));
      }
}