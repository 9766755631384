import { useNavigate } from "react-router-dom";

const useSignUpMentorFirst = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };


  return {
    handleBack,
  };
};

export default useSignUpMentorFirst;
