import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";
import { getProfile,getProfileSuccess,getProfileError } from "../actions";

export function* getProfileSaga({ params }) {
  yield put(getProfile())
  try {
    const response = yield call(routes.getProfile, params);
    yield put(getProfileSuccess(response));
  } catch (error) {
    yield put(getProfileError(error));
  }
}