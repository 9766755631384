import React, { useState } from "react";
import "./InviteFriendModal.scss";
import useInviteFriend from "../../hooks/useInviteFriend";

const InviteFriendModal = ({
  isOpened,
  img,
  title,
  text,
  leftBtnText,
  rightBtnText,
  closeModal,
  from,
}) => {
  const { email, setEmail, message, setMessage, sendInvitation } =
    useInviteFriend(closeModal, from);

  return isOpened ? (
    <div
      className="InviteFriendModal-cont"
      onClick={() => {
        if (from === "Home") {
          // setEmail("admin@career-scoops.com");
          closeModal();
          setMessage("");
          setEmail("")
        } else {
          setEmail("");
          closeModal();
          setMessage("");
        }
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="InviteFriendModal-cont__content"
      >
        <div className="InviteFriendModal-cont__content__imgWrapper">
          <img
            src={img}
            alt="drawing"
            className="InviteFriendModal-cont__content__imgWrapper__img"
          />
        </div>
        <div className="InviteFriendModal-cont__content__textCont">
          <p className="InviteFriendModal-cont__content__textCont__title">
            {title}
          </p>
          <p className="InviteFriendModal-cont__content__textCont__text">
            {text}
          </p>
        </div>
        <div className="InviteFriendModal-cont__content__emailInpCont">
          <p className="InviteFriendModal-cont__content__emailInpCont__emailText">
            {from === "Home" ? "Email" : "Friend's Email"}
          </p>
          <input
            type="text"
            className="InviteFriendModal-cont__content__emailInpCont__inp"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="InviteFriendModal-cont__content__textAreaCont">
          <p className="InviteFriendModal-cont__content__textAreaCont__text">
            Leave Your Message
          </p>
          <textarea
            className="InviteFriendModal-cont__content__textAreaCont__textArea"
            placeholder="Type Here"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="InviteFriendModal-cont__content__btnCont">
          <button
            className="InviteFriendModal-cont__content__btnCont__firstBtn"
            onClick={() => {
              if (from === "Home") {
                setEmail("");
                closeModal();
                setMessage("");
              } else {
                setEmail("");
                closeModal();
                setMessage("");
              }
            }}
          >
            <p className="InviteFriendModal-cont__content__btnCont__firstBtn__text">
              {leftBtnText}
            </p>
          </button>
          <button
            className="InviteFriendModal-cont__content__btnCont__secondBtn"
            onClick={sendInvitation}
          >
            <p className="InviteFriendModal-cont__content__btnCont__secondBtn__text">
              {rightBtnText}
            </p>
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default InviteFriendModal;
