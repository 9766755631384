import React, { useEffect, useRef } from "react";
import "./TagInfo.scss";
const TagInfo = ({ isOpened, info, closeModal, isbtnVisible, selectedId }) => {
  const modalRef = useRef();

  useEffect(() => {
    if (!isbtnVisible) {
      const listener = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target) && event.target.className.indexOf('close__popup__hover') < 0) {
          closeModal();

        }
      };
      document.addEventListener("mousedown", listener, false);
      return () => {
        document.removeEventListener("mousedown", listener);
      };
    }
  }, [modalRef, closeModal]);

  // let style = {};
  // if (modalRef.current) {
  //   const windowWidth = document.body.scrollWidth || window.innerWidth;
  //   const coords = modalRef.current.getBoundingClientRect();
  //   const rightDistance = windowWidth - coords.left - modalRef.current.offsetWidth;
  //   const leftDistance = coords.left;
  //   style = rightDistance < 0 ? { left: 0 } : leftDistance < 0 ? { left: '100%' } : {};
  //   console.log(style)
  // }
  return isOpened ? (
    <div className="tagInfo-cont"
      ref={!isbtnVisible ? modalRef : null}
    >
      <p className="tagInfo-cont__text">{info}</p>
      {isbtnVisible ? (
        <div className="tagInfo-cont__btnWrapper">
          <button className="tagInfo-cont__btnWrapper__btn">
            <p
              className="tagInfo-cont__btnWrapper__btn__text"
              onClick={closeModal}
            >
              I got it
            </p>
          </button>
        </div>
      ) : null}
    </div>
  ) : null;
};
export default TagInfo;
