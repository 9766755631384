import React from "react";
import { useOutletContext } from "react-router-dom";
import Add from "../../../../assets/Add.svg";
import minus from "../../../../assets/minus.svg";

import "./FaqItems.scss";

const FaqItems = () => {
  const [questions, setQuestions] = useOutletContext();

  const handleClick = (id) => {
    setQuestions((prev) =>
      prev.map((item) =>
        item.id === id
          ? { ...item, isOpen: !item.isOpen }
          : { ...item, isOpen: false }
      )
    );
  };

  const firstHalf = questions.filter((item) => item.id % 2 === 0);
  const secondHalf = questions.filter((item) => item.id % 2 === 1);
  return (
    <div className="FaqItems">
      <div className="FaqItems-col">
        {firstHalf.map((item) => (
          <div
            onClick={() => handleClick(item.id)}
            key={item.id}
            className="FaqItems-col-element"
          >
            <div className="FaqItems-col-element__question">
              <p>{item.question}</p>
              <div className="FaqItems-col-element__image">
                <img src={item.isOpen ? minus : Add} alt="" />
              </div>
            </div>
            {item.isOpen && (
              <p className={`FaqItems-col-element__answere`}>{item.answere}</p>
            )}
          </div>
        ))}
      </div>
      <div className="FaqItems-col">
        {secondHalf.map((item) => (
          <div
            onClick={() => handleClick(item.id)}
            key={item.id}
            className="FaqItems-col-element"
          >
            <div className="FaqItems-col-element__question">
              <p>{item.question}</p>
              <div className="FaqItems-col-element__image">
                <img src={item.isOpen ? minus : Add} alt="" />
              </div>
            </div>
            {item.isOpen && (
              <p className={`FaqItems-col-element__answere`}>{item.answere}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqItems;
