import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { TagInfo } from "../../../../modals";
import Scenario2Intro from "../../../../assets/Scenario2Intro.svg";
import "./Questions.scss";
import classNames from "classnames";

const Dragable = ({
  text,
  id,
  isDropped,
  intro,
  selectedId,
  setSelectedId,
  handlDrag,
  description,
  setIntro,
  openTagModal,
  closeTagModal,
}) => {
  const [{ isDragging, item }, drag, preview] = useDrag(() => {
    return {
      type: "tag",
      item: { id, text },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
          item: monitor.getItem(),
        };
      },
    };
  });
  const [isItemDragging, setIsItemDragging] = useState(false);
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);
  useEffect(() => {
    setIsItemDragging(isDragging ? true : false);
    handlDrag(isDragging);
  }, [isDragging]);
  useEffect(() => {
    if (isDragging && intro) {
      setIntro(false);
    }
  }, [isDragging, intro]);
  return !isItemDragging && !isDropped ? (
    <>
      {intro && selectedId === id && (
        <div className="scenario2-Questions__items__QuetionsCont__intro">
          <img src={Scenario2Intro} alt="" />
          <p>Click on the section to learn more about the skill</p>
        </div>
      )}
      <div
        className={classNames(
          "scenario2-Questions__items__QuetionsCont__question",
          {
            isSelected: selectedId === id,
          }
        )}
        ref={drag}
        onClick={() => {
          setSelectedId(id);
        }}
      >
        <p className="scenario2-Questions__items__QuetionsCont__question__text">
          {text}
        </p>
      </div>
      <TagInfo
        isbtnVisible={true}
        info={description}
        isOpened={selectedId === id}
        closeModal={() => {
          setSelectedId(null);
          setIntro(false);
        }}
      />
    </>
  ) : (
    <div className=" tagWithouText">
      <p className="unvisibleText">{text}</p>
    </div>
  );
};
export default Dragable;
