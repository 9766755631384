import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ScenarioFooter from "../../../../components/ScenarioFooter";
import StatusBar from "../../../../components/StatusBar";
import More from "../../../../assets/more.svg";
import Answers from "./Answers";
import useQuestion1 from "../../../../hooks/useQuestion1";

import "./Questions.scss";

const Question = ({ data, total, progress, images }) => {
  const { description, id, image, question, title } = data;
  const { answer, setAnswer } = useQuestion1(id);
  const [showMore, setShowMore] = useState(false);
  return (
    <section className="Question">
      <div className="Question-cont-desktop">
        {images[0] ? <div className="Question-cont__img">
          <img src={images[0]} alt="" />
          <p>The Titans</p>
        </div>: <div className="Question-cont__emptyimg"></div>}
        <div className="Question-cont__content">
          <h1>SCENARIO 1</h1>
          <p className="Question-cont__content__text">
            {showMore ? description : description.substring(0, 250)}{" "}
          </p>
          <h3 onClick={() => setShowMore((prev) => !prev)}>
            {showMore ? "Less" : "More"}
            <span>
              <img src={More} alt="" />
            </span>
          </h3>
          <p className="Question-cont__content__text">
            Which team would you want be on?
          </p>
          <div className="Question-cont__content__wrapper">
            <StatusBar activeIndex={+progress} totalAmount={total} />
          </div>
          <p className="Question-cont__content__info">
            {question}
          </p>
          <div className="Question-cont__content__select">
            <p>Not at all likely</p>
            <Answers answer={answer} setAnswer={setAnswer} />
            <p>Extremely likely</p>
          </div>
        </div>
        {images[1] && <div className="Question-cont__img">
          <img src={images[1]} alt="" />
          <p>The Dragons</p>
        </div>}
      </div>
      <div className="question-cont-mobile-cont">
        <div className="question-cont-mobile-cont__header">
          {images[0] ? <div className="Question-cont__img">
            <img src={images[0]} alt="" />
            <p>The Titans</p>
          </div> : <div className="Question-cont__emptyimg"></div>}
          <div className="Question-cont__content">
            <h1>SCENARIO 1</h1>
          </div>
          {images[1] && <div className="Question-cont__img">
            <img src={images[1]} alt="" />
            {images[0] && <p>The Dragons</p>}
          </div>}
        </div>
        <div className="question-cont-mobile">
          <p className="question-cont-mobile__text">
            {showMore ? description : description.substring(0, 250)}{" "}
          </p>

          <h3 onClick={() => setShowMore((prev) => !prev)}>
            {showMore ? "Less" : "More"}
            <span>
              <img src={More} alt="" />
            </span>
          </h3>

          <p className="question-cont-mobile__text">
            Which team would you want be on?
          </p>
          <div className="question-cont-mobile__wrapper">
            <StatusBar activeIndex={+progress} totalAmount={total} />
          </div>
          <p className="question-cont-mobile__info">
            {question}
          </p>
          <div className="question-cont-mobile__select">
            <div className="question-cont-mobile__onlyselect">
              <Answers answer={answer} setAnswer={setAnswer} />
            </div>

            <div>
              <p>Not at all likely</p>
              <p>Extremely likely</p>
            </div>
          </div>
        </div>
      </div>

      <ScenarioFooter
        variation="question"
        buttons={{ next: { disabled: answer ? false : true, text: 'NEXT' } }}
      />
    </section>
  );
};

export default Question;
