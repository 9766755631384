import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Question,
  Question6,
} from "../pages/ScenarioQuestions/questions/Scenario1";
import { Question7 } from "../pages/ScenarioQuestions/questions/Scenario2";
import { Question10 } from "../pages/ScenarioQuestions/questions/Scenario3";
import { Question13 } from "../pages/ScenarioQuestions/questions/Scenario4";
import { Scenario1, Scenario2, Scenario4 } from "../pages/Scenario/components";
import {
  initSubmitScenarios,
  resetSavingResult,
} from "../redux/actions/scenariosActions";
import { LocalStorage } from "../common/localStorage";
import useWindowWidth from "./useWindowWidth";

import baseBall from "../assets/baseBall.png";
import hat from "../assets/hat.png";
import baseBall1 from "../assets/baseball1.png";
import cup from "../assets/cup.png";
import hand from "../assets/hand.png";
import fri from "../assets/fri.png";
import rupr from "../assets/rupr.png";
import burger from "../assets/burger.png";
import watch from "../assets/watch.png";

const scenario1Images = [
  [baseBall, hat],
  [baseBall1, cup],
  [hand, fri],
  [rupr, burger],
  [null, watch],
];
function endsWithNumber(str) {
  return /[0-9]+$/.test(str);
}

const useScenarioQuestions = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { scenarios, savingResult } = useSelector(
    (state) => state.scenariosReducer
  );
  const { scenarioId, questionId } = useParams();
  const [mappingArray, setMappingArray] = useState([]);
  const dispatch = useDispatch();
  const { isMobile } = useWindowWidth();

  function handleSubmitResults() {
    const storedAnswers = LocalStorage.getFromLocalStorage("scenarios");
    const results = scenarios
      .filter((scenario) => scenario.scenario_id > 0)
      .map((scenario) => {
        const { id } = scenario.questions[0];
        switch (scenario.scenario_id) {
          case 1:
          case 3:
            return {
              scenario_id: scenario.scenario_id,
              answers: scenario.questions.map((question) => {
                return {
                  id: question.id,
                  answer: storedAnswers[question.id],
                };
              }),
            };
          case 2:
            if (!storedAnswers[id]) {
              navigate(`/scenario/0`);
            }
            return {
              scenario_id: scenario.scenario_id,
              answers: Object.values(storedAnswers[id]).flatMap((question, i) =>
                question.map((q) => {
                  return {
                    id: q.id,
                    answer: i + 1,
                  };
                })
              ),
            };
          case 4:
            return {
              scenario_id: scenario.scenario_id,
              answers: [
                {
                  id,
                  answer: Object.values(storedAnswers[id])
                    .map((question) => question.text)
                    .toString(),
                },
              ],
            };
          default:
            return null;
        }
      });
    dispatch(initSubmitScenarios(results));
  }

  useEffect(() => {
    if (savingResult) {
      navigate(`/results`);
      dispatch(resetSavingResult());
    }
  }, [savingResult]);

  useEffect(() => {
    const arr = [];
    scenarios.forEach((scenario, i) => {
      switch (scenario.scenario_id) {
        case 0:
          arr.push({
            component: <Scenario1 src={scenario.questions[0].image_1_url} />,
            scenarioId: 1,
            questionId: null,
            className: "Scenario",
          });
          break;
        case 1:
          let i = 0;
          const allQuestions = scenario.questions.filter(
            (question) => !endsWithNumber(question.question_id)
          );
          allQuestions.forEach((question, index) => {
            arr.push({
              component: (
                <Question
                  data={question}
                  total={allQuestions.length + 1}
                  progress={++i}
                  images={scenario1Images[index]}
                />
              ),
              scenarioId: scenario.scenario_id,
              questionId: question.id,
            });
          });
          arr.push({
            component: (
              <Question6
                data={scenario.questions.filter((question) =>
                  endsWithNumber(question.question_id)
                )}
                total={allQuestions.length + 1}
                progress={++i}
              />
            ),
            scenarioId: scenario.scenario_id,
            questionId: "c",
            className: "ScenarioQuestions",
          });
          break;
        case 2:
          !isMobile &&
            arr.push({
              component: <Scenario2 />,
              scenarioId: scenario.scenario_id,
              questionId: null,
              className: "Scenario",
            });
          arr.push({
            component: (
              <Question7
                questions={scenario.questions}
                questionId={scenario.questions[0].id}
              />
            ),
            scenarioId: scenario.scenario_id,
            questionId: scenario.questions[0].id,
            className: "ScenarioQuestions",
          });
          break;
        case 3:
          const length = scenario.questions.length;
          scenario.questions.forEach((question, ind) => {
            arr.push({
              component: (
                <Question10
                  handleSubmitResults={handleSubmitResults}
                  data={question}
                  total={length}
                  progress={++ind}
                />
              ),
              scenarioId: scenario.scenario_id,
              questionId: question.id,
              className: "ScenarioQuestions",
            });
          });
          break;
        case 4:
          isMobile &&
            arr.push({
              component: <Scenario4 />,
              scenarioId: scenario.scenario_id,
              questionId: null,
              className: "Scenario",
            });
          arr.push({
            component: (
              <Question13
                handleSubmitResults={handleSubmitResults}
                data={scenario.questions[0]}
              />
            ),
            scenarioId: scenario.scenario_id,
            questionId: scenario.questions[0].id,
            className: "ScenarioQuestions",
          });
          break;
        default:
          break;
      }
    });
    setMappingArray(arr);
  }, [scenarios, isMobile]);
  return {
    scenarioId,
    questionId,
    pathname,
    scenarios,
    mappingArray,
  };
};

export default useScenarioQuestions;
