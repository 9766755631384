import { useEffect, useState } from "react";
import CupNone from "../assets/cupNone.svg";
import CupRealistic from "../assets/cupRealistic.svg";
import CupArtistic from "../assets/cupArtisitic.svg";
import CupInvestigative from "../assets/cupInvestigative.svg";
import CuupSocial from "../assets/cupSocial.png";
import CupeEnterprising from "../assets/cupEnterPrising.svg";
import CupConventional from "../assets/cupConventional.svg";
import { LocalStorage } from "../common/localStorage";
const useQuestion13 = (data) => {
  const storedAnswers = LocalStorage.getFromLocalStorage("scenarios");
  const initialIds = Object.values(storedAnswers?.[data.id] ?? [])?.length
    ? Object.values(storedAnswers[data.id]).map((item) => item.id)
    : [];
  const [droppedIds, setDropeedIds] = useState(initialIds);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [itemImages, setItemImages] = useState(
    [
      {
        image: CupNone,
        id: 1,
      },
      {
        image: CupRealistic,
        id: 2,
      },
      {
        image: CupArtistic,
        id: 3,
      },
      {
        image: CupInvestigative,
        id: 4,
      },
      {
        image: CuupSocial,
        id: 5,
      },
      {
        image: CupeEnterprising,
        id: 6,
      },
      {
        image: CupConventional,
        id: 7,
      },
    ]);
  const [items, setItems] = useState(
    [
      {
        text: "None",
        id: 1,
        isDroped: false,
        description:"Individuals typically have a combination of personality types that influences their interests. If you found yourself in a pickle to choose all three, feel free to drop this tile in one of the spots!"
      },
      {
        text: "Realistic",
        id: 2,
        isDroped: false,
        description:"Individuals with this personality type are practical, down-to-earth, and straightforward. They are often described as no-nonsense and matter-of-fact. They have a strong sense of what is realistic and practical, and they tend to focus on the here and now."
      },
      {
        text: "Artistic",
        id: 3,
        isDroped: false,
        description:"Individuals with this personality type tend to be highly imaginative, intuitive, and sensitive to their surroundings. They often possess a natural talent for the arts, including music, dance, visual arts, and writing."
      },
      {
        text: "Investigative",
        id: 4,
        isDroped: false,
        description:"An investigative personality type is one that is characterized by a strong desire to understand how things work. People with this type of personality are often curious and analytical, and they enjoy investigating complex problems and finding solutions."
      },
      {
        text: "Social",
        id: 5,
        isDroped: false,
        description:"Individuals with a social personality type tend to be outgoing, sociable, and friendly. They enjoy being around people and thrive in social situations. They are typically good listeners and have excellent communication skills."
      },
      {
        text: "Enterprising",
        id: 6,
        isDroped: false,
        description:"Individuals with enterprising personality are typically ambitious, competitive and enjoy taking risks. They are natural leaders, persuasive and confident in their abilities. They possess excellent communication skills and are always looking for opportunities to grow and expand their horizons. They are not afraid to take on new challenges and are willing to invest time and resources to achieve their goals."
      },
      {
        text: "Conventional",
        id: 7,
        isDroped: false,
        description:"Individuals with this personality types tend to be detail-oriented and enjoy structure and predictability in their lives. The conventional personality type is often described as rule-abiding, organized, and reliable."
      },
    ].map((item) => {
      if (
        storedAnswers &&
        storedAnswers[data.id] &&
        Object.values(storedAnswers[data.id])?.some(
          (items) => items.id === item.id
        )
      ) {
        return {
          ...item,
          isDroped: true,
        };
      }
      return item;
    })
  );
  const [dashBoardItems, setDashBoardItems] = useState(
    (storedAnswers && storedAnswers[data.id]) || {
      1: {},
      2: {},
      3: {},
    }
  );
  const [selectedId, setSelectedId] = useState(null)
  const addItemTodashBoard = (itemId, monitor) => {
    const mapKeys = [...monitor.internalMonitor.registry.dropTargets.keys()];
    const newItem = items?.find((item) => item.id === itemId);
    const currentTarget = monitor.targetId;
    const index = mapKeys.findIndex((el) => el === currentTarget);
    setDropeedIds((prev) => {
      return [...prev, itemId];
    });
    setItems((prevItems) => {
      const updatedItems = prevItems?.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            isDroped: !item.isDroped,
          };
        }
        return item;
      });
      return updatedItems;
    });
    setDashBoardItems((prev) => {
      const val = {
        ...prev,
        [index + 1]: { ...newItem, isDroped: !newItem.isDroped },
      };
      LocalStorage.updateLocalStorage("scenarios", { [data.id]: val });
      return val;
    });
  };
  useEffect(() => {
    if (droppedIds.length === 3) {
      setNextDisabled(false);
    }
    if (droppedIds.includes(1)) {
      setNextDisabled(false);
    }
  }, [droppedIds]);
  useEffect(() => {
    const removedId = droppedIds.find((id) => {
      if (
        id !== dashBoardItems[1].id &&
        id !== dashBoardItems[2].id &&
        id !== dashBoardItems[3].id
      ) {
        return true;
      } else {
        return false;
      }
    });
    const newDroppedIds = droppedIds.filter((id) => {
      if (id === removedId) {
        return false;
      } else {
        return true;
      }
    });
    setItems((prevItems) => {
      const updatedItems = prevItems?.map((item) => {
        if (item.id === removedId) {
          return {
            ...item,
            isDroped: !item.isDroped,
          };
        }
        return item;
      });
      return updatedItems;
    });
    setDropeedIds(newDroppedIds);
  }, [dashBoardItems]);
  const openModal = (id) => {
    setSelectedId(oldId => {
      if (id === oldId) {
        setIsModalOpened(false)
        return null
      } else {
        setIsModalOpened(true)
        return id
      }
    })
  };
  const closeModal = () => {
    setIsModalOpened(false)
    setSelectedId(null)
  };


  return {
    items,
    itemImages,
    dashBoardItems,
    nextDisabled,
    selectedId,
    addItemTodashBoard,
    openModal,
    closeModal
  };
};

export default useQuestion13;
