import React from "react";
import ScenarioFooter from "../../../../components/ScenarioFooter/ScenarioFooter";
import Dragable from "./Question7Dragable";
import ConeCont from "./Question7MultiCont";
import partIccreamCone from "../../../../assets/partOfIcecramCone.png";
import coneWithoutPart from "../../../../assets/coneWithoutPart.png";
import useQuestion7 from "../../../../hooks/useQuestion7";
import SlopeIceCreamRight from "../../../../assets/SlopeIceCreamRight.png";
import SlopeIceCReamLeft from "../../../../assets/slopeIceCreamLeft.png";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import classNames from "classnames";
import { SkillModal } from "../../../../modals";
import "./Questions.scss";
const Question7 = ({ questionId, questions }) => {
  const {
    tags,
    rates,
    selectedTagId,
    coneItems,
    intro,
    nextDisabled,
    isEmpty,
    isModalOpened,
    setIntro,
    handleRemoveItem,
    addTagToIceCone,
    handlDrag,
    setSelectedId,
    selectTag,
    closeModal,
    sortTag,
    removeItem
  } = useQuestion7(questionId, questions);
  const choosenTag = tags.find((el) => (selectedTagId === el.id))
  const { isMobile, width } = useWindowWidth();
  return (
    <section className="scenario2-Questions">
      {isMobile ? (
        <div className="scenario2-Questions__cont">
          <div className="scenario2-Questions__cont__imgs">
            <img src={SlopeIceCReamLeft} alt="SlopeIceCReamLeft" />
            <img src={SlopeIceCreamRight} alt="SlopeIceCreamRight" />
          </div>
          <div className="scenario2-Questions__items">
            <p className="scenario2-Questions__items__intro">SCENARIO 2</p>
            <p className="scenario2-Questions__items__title">
              {" "}
              How well do these skills match the scoop that is you?
            </p>
            <div className="scenario2-Questions__items__QuetionsCont">
              {tags.map((el) => (
                <div
                  className={classNames("scenario2-Questions__items__QuetionsCont__itemWrapper", {
                    greenBorder: el.isDropped
                  })}
                  key={el.id}
                  onClick={() => {
                    if (el.isDropped) {
                      removeItem(el.id)
                      setSelectedId(el.id)
                    }
                    else {
                      selectTag(el.id)
                    }
                  }}
                >
                  <p className={classNames("scenario2-Questions__items__QuetionsCont__itemWrapper__item", {
                    greenText: el.isDropped
                  })}>
                    {el.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <SkillModal
            title={choosenTag?.text}
            isOpened={isModalOpened}
            close={closeModal}
            sortTag={sortTag}
            description={choosenTag?.description}
          />
        </div>
      ) : (
        <div className="scenario2-Questions__cont">
          <div className="scenario2-Questions__items">
            <p
              className={classNames("scenario2-Questions__items__intro", {
                unVisibleIntro: !isMobile,
              })}
            >
              SCENARIO 2
            </p>
            <div>
              <p className="scenario2-Questions__items__title">
                How well do these skills match the scoop that is you?
              </p>
              <p className="scenario2-Questions__items__hint">Drag and drop tags into the ice cream cone</p>
            </div>
            <div className="scenario2-Questions__items__QuetionsCont">
              {tags.map((el) => (
                <div
                  className="scenario2-Questions__items__QuetionsCont__items-item"
                  key={el.id}
                >
                  <Dragable
                    key={el.id}
                    text={el.text}
                    description={el.description}
                    isDropped={el.isDropped}
                    id={el.id}
                    intro={intro}
                    setIntro={setIntro}
                    handlDrag={handlDrag}
                    setSelectedId={setSelectedId}
                    selectedId={selectedTagId}
                  />
                </div>
              ))}
            </div>
          </div>
          <div
            className={classNames("scenario2-Questions__imgWrapper", {
              unVisibleImages: isMobile,
            })}
          >
            <div className="scenario2-Questions__imgWrapper__inner">
              <div className="scenario2-Questions__imgWrapper__inner__imgWrapper">
                <img src={partIccreamCone} alt="partIccreamCone" />
                <div className="scenario2-Questions__imgWrapper__inner__ratedContainer">
                  <ConeCont
                    handleRemoveItem={handleRemoveItem}
                    addTagToIceCone={addTagToIceCone}
                    data={coneItems[1]}
                    index={1}
                  />
                </div>
                <img
                  src={coneWithoutPart}
                  alt="coneWithoutPart"
                  className="scenario2-Questions__imgWrapper__inner__imgWrapper__bigCone"
                />
              </div>
              <div className="scenario2-Questions__imgWrapper__inner__imgWrapper">
                <img src={partIccreamCone} alt="partIccreamCone" />
                <div className="scenario2-Questions__imgWrapper__inner__ratedContainer">
                  <ConeCont
                    handleRemoveItem={handleRemoveItem}
                    addTagToIceCone={addTagToIceCone}
                    data={coneItems[2]}
                    index={2}
                  />
                </div>
                <img
                  src={coneWithoutPart}
                  alt="coneWithoutPart"
                  className="scenario2-Questions__imgWrapper__inner__imgWrapper__bigCone"
                />
              </div>
              <div className="scenario2-Questions__imgWrapper__inner__imgWrapper">
                <img src={partIccreamCone} alt="partIccreamCone" />
                <div className="scenario2-Questions__imgWrapper__inner__ratedContainer">
                  <ConeCont
                    handleRemoveItem={handleRemoveItem}
                    addTagToIceCone={addTagToIceCone}
                    data={coneItems[3]}
                    index={3}
                  />
                </div>
                <img
                  src={coneWithoutPart}
                  alt="coneWithoutPart"
                  className="scenario2-Questions__imgWrapper__inner__imgWrapper__bigCone"
                />
              </div>
              <div className="scenario2-Questions__imgWrapper__inner__imgWrapper">
                <img src={partIccreamCone} alt="partIccreamCone" />
                <div className="scenario2-Questions__imgWrapper__inner__ratedContainer">
                  <ConeCont
                    handleRemoveItem={handleRemoveItem}
                    addTagToIceCone={addTagToIceCone}
                    data={coneItems[4]}
                    index={4}
                  />
                </div>
                <img
                  src={coneWithoutPart}
                  alt="coneWithoutPart"
                  className="scenario2-Questions__imgWrapper__inner__imgWrapper__bigCone"
                />
              </div>
              <div className="scenario2-Questions__imgWrapper__inner__imgWrapper">
                <img src={partIccreamCone} alt="partIccreamCone img" />
                <div className="scenario2-Questions__imgWrapper__inner__ratedContainer">
                  <ConeCont
                    handleRemoveItem={handleRemoveItem}
                    addTagToIceCone={addTagToIceCone}
                    data={coneItems[5]}
                    index={5}
                  />
                </div>
                <img
                  src={coneWithoutPart}
                  alt="coneWithoutPart"
                  className="scenario2-Questions__imgWrapper__inner__imgWrapper__bigCone"
                />
              </div>
            </div>
            <div className="scenario2-Questions__imgWrapper__ratesCont">
              {rates.map((rate, index) => (
                <React.Fragment key={index}>
                  <div
                    key={index}
                    className="scenario2-Questions__imgWrapper__ratesCont__rate"
                  >
                    {(index === 0 || index === 4) && isEmpty && (
                      <div className="scenario2-Questions__imgWrapper__ratesCont__rate__tooltip">
                        {" "}
                        {`${index === 0
                            ? "I am not good at it"
                            : "I am great at it"
                          }`}{" "}
                      </div>
                    )}
                    <span>{rate}</span>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
      <ScenarioFooter
        variation="question"
        buttons={{
          next: {
            highLightColor: nextDisabled ? false : true,
            disabled: nextDisabled,
            text: "NEXT SCENARIO",
          },
          back: { text: "PREVIOUS" },
        }}
        where={`/scenario/${3}/${1}`}
      />
      {!isMobile && intro && <div className="scenario2-Questions_intro"></div>}
    </section>
  );
};

export default Question7;
