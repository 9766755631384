import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authIsAuthenticated } from "../../../../../../redux/selectors/authSelectors";

import AiRobot from "../../../../../../assets/AiRobot.png";
import "./AiThinking.scss";

import useThinking from "../../../../../../hooks/useThinking";
import RecommendationRetrievalModal from "../../../../../../modals/RecommendationRetrievalModal";

const AiThinking = () => {
  const { text } = useThinking();
  const [isRetrievalModalOpen, setRetrievalModalOpen] = useState(false);
  const isAuth = useSelector((state) => authIsAuthenticated(state));
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      navigate("/signin?redirect=/results");
    }

    const timer = setTimeout(() => {
      setRetrievalModalOpen(true);
    }, 90000); // 90 seconds, change if you want to test

    return () => {
      clearTimeout(timer);
    };
  }, [isAuth, navigate]);

  return (
    <div className="AiThinking">
      <RecommendationRetrievalModal
        isOpen={isRetrievalModalOpen}
        onClose={() => setRetrievalModalOpen(false)}
      />
      <div className="AiThinking__imgWrapper">
        <img
          src={AiRobot}
          alt="AiRobot"
          className="AiThinking__imgWrapper__img"
        />
      </div>
      <div className="AiThinking__textsWrapper">
        <p className="AiThinking__textsWrapper__yellowText">
          Our engine is calculating your career recommendations...
        </p>
        <p className="AiThinking__textsWrapper__SecondText">{text}</p>
      </div>
    </div>
  );
};

export default AiThinking;
