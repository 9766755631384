import React from "react";
import "./Success.scss";
const Success = ({ image, title, text, isOpened, close }) => {
  return isOpened ? (
    <div className="Success" onClick={close}>
      <div className="Success__content" onClick={(e) => e.stopPropagation()}>
        <div className="Success__content__heading">
          <div className="Success__content__heading__imgWrapper">
            <img
              src={image}
              alt="Success"
              className="Success__content__heading__imgWrapper__img"
            />
          </div>
          <p className="Success__content__heading__title">{title}</p>
          <p className="Success__content__heading__text">{text}</p>
        </div>
        <button className="Success__content__btn" onClick={close}>
          <p className="Success__content__btn__ok">OK</p>
        </button>
      </div>
    </div>
  ) : null;
};

export default Success;
