import * as actionTypes from "../actionTypes";
const initialState = {
  error: null,
};

function recommendationReducer (state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.GET_RECOMMENDATIONS:
      return {
        ...state,
      };
    case actionTypes.GET_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        recommendation: payload,
      };
    case actionTypes.GET_RECOMMENDATIONS_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export default recommendationReducer
