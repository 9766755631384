import React, { useState } from "react";
import { Link } from "react-router-dom";
import lineBack from "../../assets/lineBack.svg";
import lineNext from "../../assets/lineNext.svg";
import lineNextGreen from "../../assets/lineNextGreen.svg";
import { useScenarioFooter } from "../../hooks";
import classNames from "classnames";
import useWindowWidth from "../../hooks/useWindowWidth";
import "./ScenarioFooter.scss";

const ScenarioFooter = ({ where, variation, buttons, handleSubmitResults }) => {
  const { isMobile, width } = useWindowWidth();
  const { handleBack, scenarioId, mappingArray, disabled, setDisabled } = useScenarioFooter();

  function getNextButtonConent() {
    return (
      <>
        <p
          className={classNames("Scenario-footer__forQuestsions__text", {
            __highlighted: buttons?.next?.highLightColor,
          })}
        >
          {!(width <= 544)
            ? buttons?.next?.text || "NEXT"
            : "NEXT"}
        </p>
        <div className="Scenario-footer__forQuestsions__imgWrapper">
          <img
            src={buttons?.next?.highLightColor ? lineNextGreen : lineNext}
            alt="lineNext"
            className="Scenario-footer__forQuestsions__imgWrapper__img"
          />
        </div>
      </>
    );
  }
  return (
    <footer className="Scenario-footer">
      {variation === "question" ? (
        <>
          <div className="Scenario-footer__forQuestsions" onClick={handleBack}>
            <div className="Scenario-footer__forQuestsions__imgWrapper">
              <img
                src={lineBack}
                alt="lineBack"
                className="Scenario-footer__forQuestsions__imgWrapper__img"
              />
            </div>
            <p className="Scenario-footer__forQuestsions__text">
              {!(width <= 544)
                ? buttons?.back?.text || "PREVIOUS"
                : (buttons?.back?.text === "PREVIOUS SCENARIO" ? "PREV SCEN" : "PREV") || "PREV"}
            </p>
          </div>
          {buttons?.next?.disabled || disabled ? (
            <div className={"Scenario-footer__forQuestsions __disabled"}>
              {getNextButtonConent()}
            </div>
          ) : (
            mappingArray.length - 1 === Number(scenarioId) ?
              <div onClick={() => {
                setDisabled(true);
                handleSubmitResults(mappingArray)
              }} className="Scenario-footer__forQuestsions">
                {getNextButtonConent()}
              </div> :
              <Link to={`/scenario/${Number(scenarioId) + 1}`} className="Scenario-footer__forQuestsions">
                {getNextButtonConent()}
              </Link>
          )}
        </>
      ) : (
        <div className="Scenario-footer__forAnswer">
          <Link to={`/scenario/${Number(scenarioId) + 1}`} className="Scenario-footer__forAnswer__imgWrapper">
            <p className="Scenario-footer__forAnswer__imgWrapper__text">
              ANSWER
            </p>
            <img src={lineNextGreen} alt="lineNext" />
          </Link>
        </div>
      )}
    </footer>
  );
};

export default ScenarioFooter;
