import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authUser } from "../redux/selectors/authSelectors";
import useWindowWidth from "./useWindowWidth";
import { useNavigate } from "react-router-dom";
import { initSetProfile } from "../redux/actions";
const useProfileInfo = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.profileReducer);
  const user = useSelector(authUser);
  const { role } = user || {};
  const [isProfilePicModalOpened, setIsProfilePicOpened] = useState(false);
  const [imageFile, SetImageFile] = useState(null);
  const [image, setIMage] = useState(user?.image);
  const { width, isMobile } = useWindowWidth();
  useEffect(() => {
    if(profile?.profile_image){
    getImage(profile.profile_image)
  }
  }, [profile?.profile_image])
  const openPrfilePicModal = () => {
    if (isMobile) {
      navigate("/profile/changePhoto")
    } else {
      setIsProfilePicOpened(true);
    }
  };
  const closeProfilePicModal = () => {
    setIsProfilePicOpened(false);
  };
  const getImageFile = (fileObj) => {
    if(!fileObj){
      return null
    }
    SetImageFile(fileObj);
    const param = { ...profile }
    delete param.school
    dispatch(
      initSetProfile({
        ...param,
        profile_image: fileObj,
      })
    );
  };

  const getImage = (url) => {
    setIMage(url);
  };
  return {
    profile,
    isProfilePicModalOpened,
    image,
    role,
    imageFile,
    width,
    openPrfilePicModal,
    closeProfilePicModal,
    getImageFile,
    getImage,
  };
};

export default useProfileInfo;
