import React, { useState } from "react";
import classnames from "classnames";
import { ActiveSessions, SessionHistory } from "./components";
import "./Sessions.scss";
import CustomSession from "./components/CustomSession/CustomSession";
import { useSelector } from "react-redux";
import { authUser } from "../../../../redux/selectors/authSelectors";

const Sessions = () => {
  const [activeBtnText, setActiveBtnText] = useState("Session History");
  const storageUser = useSelector((state) => authUser(state));
  const { role } = storageUser || {};
  // console.log(storageUser)
  return (
    <>
      <div className="sessionBtn-cont">
        <button
          onClick={(e) => {
            setActiveBtnText("Session History");
          }}
          className={classnames("sessionBtn-cont__btn", {
            activeBtn: activeBtnText === "Session History",
          })}
        >
          <p
            className={classnames("sessionBtn-cont__btn__text", {
              activeBtnText: activeBtnText === "Session History",
            })}
          >
            Session History
          </p>
        </button>
        <button
          onClick={(e) => {
            setActiveBtnText("Active Sessions");
          }}
          className={classnames("sessionBtn-cont__btn", {
            activeBtn: activeBtnText === "Active Sessions",
          })}
        >
          <p
            className={classnames("sessionBtn-cont__btn__text", {
              activeBtnText: activeBtnText === "Active Sessions",
            })}
          >
            Active Sessions
          </p>
        </button>
      </div>
      {activeBtnText === "Session History" ? (
        <SessionHistory />
      ) :
        activeBtnText === "Active Sessions" ?
          <ActiveSessions /> :
          <CustomSession />
      }

    </>
  );
};

export default Sessions;
