import { useEffect, useState } from "react";
import { LocalStorage } from "../common/localStorage";

const useQuestion7 = (questionId, questions) => {
  const storedAnswers = LocalStorage.getFromLocalStorage("scenarios");
  const [nextDisabled, setNextDisabled] = useState(true);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [tags, setTags] = useState(
    questions.map((item) => {
      return {
        text: item.question.split("? ")[1],
        id: item.id,
        isDropped:
          storedAnswers &&
          storedAnswers[questionId] &&
          Object.values(storedAnswers[questionId]).some((it) =>
            it.some((i) => i.id === item.id)
          )
            ? true
            : false,
            description:item.description    
      };
    })
  );

  useEffect(() => {
    setNextDisabled(tags.some((item) => !item.isDropped));
  }, [tags]);
  const [isDragging, setIsDragging] = useState(false);
  const rates = [1, 2, 3, 4, 5];
  const [selectedTagId, setSelectedTagId] = useState(
    storedAnswers && storedAnswers[questionId] ? null : questions[1].id
  );
  const [intro, setIntro] = useState(
    storedAnswers && storedAnswers[questionId] ? false : true
  );
  const [isEmpty, setEmpty] = useState(
    storedAnswers && storedAnswers[questionId] ? false : true
  );
  const [coneItems, setConeItems] = useState(
    (storedAnswers && storedAnswers[questionId]) || {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
    }
  );
  const handleRemoveItem = (tagId, index) => {
    const newTag = tags?.find((tag) => tag.id === tagId);
    setTags((prevTags) => {
      const updatedTags = prevTags?.map((tag) => {
        if (tag.id === tagId) {
          return {
            ...tag,
            isDropped: false,
          };
        }
        return tag;
      });
      return updatedTags;
    });
    setConeItems((prev) => {
      const val = {
        ...prev,
        [index]: prev[index].filter((tag) => tag.id !== newTag.id),
      };
      LocalStorage.updateLocalStorage("scenarios", { [questionId]: val });
      return val;
    });
  };
  const addTagToIceCone = (tagId, monitor) => {
    const mapKeys = [...monitor.internalMonitor.registry.dropTargets.keys()];
    const newTag = tags?.find((tag) => tag.id === tagId);
    const currentTarget = monitor.targetId;
    const index = mapKeys.findIndex((el) => el === currentTarget);
    setEmpty(false);
    setTags((prevTags) => {
      const updatedTags = prevTags?.map((tag) => {
        if (tag.id === tagId) {
          return {
            ...tag,
            isDropped: !tag.isDropped,
          };
        }
        return tag;
      });
      return updatedTags;
    });
    setConeItems((prev) => {
      const val = { ...prev, [index + 1]: [...prev[index + 1], newTag] };
      LocalStorage.updateLocalStorage("scenarios", { [questionId]: val });
      return val;
    });
  };
  const handlDrag = () => {
    setIsDragging(!isDragging);
  };
  const setSelectedId = (id) => {
    setSelectedTagId(id);
  };
  const selectTag = (id) => {
    setIsModalOpened(true);
    setSelectedTagId(id);
  };

  const sortTag = (rate) => {
    const newTag = tags?.find((tag) => tag.id === selectedTagId);
    setTags((prevTags) => {
      const updatedTags = prevTags?.map((tag) => {
        if (tag.id === selectedTagId) {
          return {
            ...tag,
            isDropped: !tag.isDropped,
          };
        }
        return tag;
      });
      return updatedTags;
    });
    setConeItems((prev) => {
      const val = { ...prev, [rate]: [...prev[rate], newTag] };
      LocalStorage.updateLocalStorage("scenarios", { [questionId]: val });
      return val;
    });
  };

  const removeItem = (id) => {
    const newTag = tags?.find((element) => (element.id === id));
    const chosenTagCone = (newTag) => {
      if (newTag?.isDropped) {
        if (coneItems[1].find((el) => newTag.id === el.id)) {
          return 1;
        } else if (coneItems[2].find((el) =>newTag.id === el.id)) {
          return 2;
        } else if (coneItems[3].find((el) => newTag.id === el.id)) {
          return 3;
        } else if (coneItems[4].find((el) => newTag.id === el.id)) {
          return 4;
        } else if (coneItems[5].find((el) => newTag.id === el.id)) {
          return 5;
        }
      }
      return null;
    };
    setTags((prevTags) => {
      const updatedTags = prevTags?.map((tag) => {
        if (tag.id === id) {
          return {
            ...tag,
            isDropped: false,
          };
        }
        return tag;
      });
      return updatedTags;
    });
    setConeItems((prev) => {
      const tagCone = chosenTagCone(newTag)
      const val = {
        ...prev,
        [tagCone]: prev[tagCone].filter((tag) => tag.id !== newTag.id),
      };
      LocalStorage.updateLocalStorage("scenarios", { [questionId]: val });
      return val;
    });
  };
  const closeModal = () => {
    setIsModalOpened(false);
  };
  return {
    tags,
    rates,
    selectedTagId,
    coneItems,
    intro,
    nextDisabled,
    isEmpty,
    isModalOpened,
    setIntro,
    handleRemoveItem,
    addTagToIceCone,
    handlDrag,
    setSelectedId,
    selectTag,
    closeModal,
    sortTag,
    removeItem
  };
};

export default useQuestion7;
