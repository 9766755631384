import * as actionTypes from "../actionTypes";

export const initScenarios = (userCredentials) => ({
  type: actionTypes.INIT_SCENARIOS,
  payload: {
    ...userCredentials,
  },
});

export const resetSavingResult = () => ({
  type: actionTypes.RESET_SAVING_RESULT
})

export const initSubmitScenarios = (results) => ({
  type: actionTypes.INIT_SUBMIT_SCENARIOS,
  payload: results
});

export const getScenarios = () => ({
  type: actionTypes.GET_SCENARIOS,
});

export const getScenariosSuccess = (payload) => ({
  type: actionTypes.GET_SCENARIOS_SUCCESS,
  payload,
});

export const getScenariosError = (error) => ({
  type: actionTypes.GET_SCENARIOS_ERROR,
  error,
});

export const setScenarios = () => ({
  type: actionTypes.SET_SCENARIOS,
});

export const setScenariosSuccess = (payload) => ({
  type: actionTypes.SET_SCENARIOS_SUCCESS,
  payload,
});

export const setScenariosError = (error) => ({
  type: actionTypes.SET_SCENARIOS_ERROR,
  error,
});

export const initGetResults = () => ({
  type: actionTypes.INIT_RESULTS
});

export const initSetResults = (results) => ( {
  type: actionTypes.INIT_SET_RESULTS,
  payload:results
});

export const getResults = () => ({
  type: actionTypes.GET_RESULTS,
});

export const getResultsSuccess = (payload) => ({
  type: actionTypes.GET_RESULTS_SUCCESS,
  payload,
});

export const getResultsError = (error) => ({
  type: actionTypes.GET_RESULTS_ERROR,
  error,
});

export const setResults = () => ({
  type: actionTypes.SET_RESULTS,
});

export const setResultsSuccess = (payload) => ({
  type: actionTypes.SET_RESULTS_SUCCESS,
  payload,
});

export const setResultsError = (error) => ({
  type: actionTypes.SET_RESULTS_ERROR,
  error,
});
