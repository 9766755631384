import React from "react";
import { Scenario3 } from "../../../Scenario/components";
import "./Questions.scss";
import useQuestion8 from "../../../../hooks/useQuestion8";

const Question10 = ({ data, total, progress, handleSubmitResults }) => {
  const { answer, setAnswer } = useQuestion8(data.id);
  return (
    <>
      <Scenario3
        back="PREVIOUS"
        next="NEXT"
        sugestionText={data.question}
        isGreen={false}
        answer={answer}
        setAnswer={setAnswer}
        data={data}
        total={total}
        progress={progress}
        handleSubmitResults={handleSubmitResults}
      />
    </>
  );
};

export default Question10;
