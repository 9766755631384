import React from "react";
import classnames from "classnames";

import "./AuthorStory.scss";

const AuthorStory = ({ text, image, name, position, hasWhiteBorder }) => {
  return (
    <div
      className={classnames("AuthorStory", {
        "white-border": hasWhiteBorder,
      })}
    >
      <p className="AuthorStory__content">{text}</p>
      <div className="AuthorStory__author">
        <div className="AuthorStory__author__img">
          <img src={image} alt="" />
        </div>
        <div className="AuthorStory__author__name">
          <p className="AuthorStory__author__name__title">{name}</p>
          <p className="AuthorStory__author__name__pos">{position}</p>
        </div>
      </div>
    </div>
  );
};

export default AuthorStory;
