import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";
import { signUp, signUpError, signUpSuccess } from "../actions";

export function* signUpSaga({ payload }) {
  yield put(signUp());
  try {
    const response = yield call(routes.signUp, payload);
    yield put(signUpSuccess(response));
  } catch (error) {
    yield put(signUpError(error));
  }
}
