import { useEffect, useState } from "react";
import { LocalStorage } from "../common/localStorage";

const useQuestion1 = (number) => {
  const storedAnswers = LocalStorage.getFromLocalStorage('scenarios');
  const [answer, setAnswerState] = useState(storedAnswers && storedAnswers[number] || null)
  const setAnswer = (value) => {
    setAnswerState(value)
    LocalStorage.updateLocalStorage('scenarios', { [number]: value })
  }
  useEffect(() => {
    setAnswerState(storedAnswers && storedAnswers[number] || null);
  }, [number])
  return {
    answer,
    setAnswer
  }
}

export default useQuestion1;