import StudentResourcesDialog from "../../../../modals/StudentResourcesDialog";
import Slider from "react-slick";
import arrowIco from "../../../../assets/slider-arrow-icon.svg";
import { memo, useCallback, useState } from "react";
import Loader from "../../../../components/Loader/loaderSpin";

// Custom Arrow Component
const ArrowButton = memo(({ className, onClick, direction }) => (
    <button className={className} onClick={onClick}>
        <img src={arrowIco} alt={direction} />
    </button>
));
const slidersettings = {
    dots: false,
    infinite: false,
    arrow: false,
    autoPlay: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    nextArrow: <ArrowButton direction="next" />,
    prevArrow: <ArrowButton direction="prev" />,
    responsive: [
        {
            breakpoint: 2500,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 2000,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1800,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

function StudentResources({ list, title, handlePage, isLoading }) {
    const [isOpen, setIsOpen] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [isPaginating, setIsPaginating] = useState(false);

    const handleOpen = useCallback((index = null) => {
        setIsOpen(index);
    }, []);
    const handleSlideChange = (slide) => {
        const val = slide + 4
        if (val === list.results.length && list.meta.number_of_pages > currentPage) {
            handlePagination()
        }
    }
    const handlePagination = useCallback(() => {
        if (isPaginating || currentPage >= list.meta?.number_of_pages) return;
        setIsPaginating(true);
        const nextPage = currentPage + 1;
        handlePage(nextPage);
        setCurrentPage(nextPage);
        setIsPaginating(false);
    }, [isPaginating, currentPage, list.meta?.number_of_pages, handlePage]);

    if (!list?.results?.length) return null;
    return <div className="profileCont__studentResources">
        <h3>{title}</h3>
        <div className="profileCont__studentResources__slider">
            {
                <Slider {...slidersettings} afterChange={handleSlideChange}>
                    {
                        list?.results?.map((items, key) => <div key={key} className="profileCont__studentResources__grid" onClick={() => handleOpen(key)}>
                            <img src={items.thumbnail} alt={items.title} />
                            <div className="profileCont__studentResources__info">
                                <h4>{items.title}</h4>
                                <h5>{items.subtitle}</h5>
                            </div>
                        </div>)
                    }
                </Slider>
            }
            {isLoading && <div className="profileCont__studentResources__sliderLoading"><Loader /></div>}
        </div>
        <StudentResourcesDialog isOpen={isOpen} list={list} onClose={() => handleOpen(null)} handlePagination={handlePagination} currentPage={currentPage} isLoading={isLoading} />
    </div>
}
export default memo(StudentResources);