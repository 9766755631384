import React from "react";
import ScenarioFooter from "../../../../components/ScenarioFooter/ScenarioFooter";
import bigIceCream from "../../../../assets/bigIceCream.png"
import smallIceCream from "../../../../assets/smalliceCream.png"
import middleIceCream from "../../../../assets/middleIcecream.png"
import "./Scenario2.scss";

const Scenario2 = () => {
  return (<>
    <section className="Scenario2Intro-cont">
      <div className="Scenario2Intro-cont__textCont">
        <p className="Scenario2Intro-cont__textCont__title">SCENARIO 2</p>
        <p className="Scenario2Intro-cont__textCont__qestion">How well do these skills match the scoop that is you?</p>
      </div>
      <div className="Scenario2Intro-cont__imgsWrapper">
        <img src={bigIceCream} alt="bigIceCream" className="Scenario2Intro-cont__imgsWrapper__bigIceCream" />
        <img src={smallIceCream} alt="smallIceCream" className="Scenario2Intro-cont__imgsWrapper__smallIceCream" />
        <img src={middleIceCream} alt=" middleIceCream " className="Scenario2Intro-cont__imgsWrapper__middleIceCream" />
        <img src={smallIceCream} alt="smallIceCream" className="Scenario2Intro-cont__imgsWrapper__smallIceCream" />
        <img src={bigIceCream} alt="bigIceCream" className="Scenario2Intro-cont__imgsWrapper__bigIceCream" />
      </div>
    </section>
    <ScenarioFooter where="1" variation={"question"} buttons={{ next: { highLightColor: true, text: 'Start' }, back: { text: 'PREVIOUS SCENARIO' } }} />
  </>)
};

export default Scenario2;
