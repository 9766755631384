import React from "react";

import AuthorStory from "../AuthorStory";
import bookDisabled from "../../../../assets/bookDisabled.svg";
import bookEnabled from "../../../../assets/bookEnabled.svg";
import {InviteFriendModal} from "../../../../modals"
import Envelope from "../../../../assets/InviteFriendEnvelope.png";
import pencil from "../../../../assets/pencil.svg";

import "./StudentsStory.scss";
import useStory from "../../../../hooks/useStory";

const StudentsStory = () => {
  const { closeModal, openModal, isModalOpened } = useStory();
  return (
    <section className="Students">
      <div className="Students-row">
        <div className="Students-row__mock__1" />
        <AuthorStory
          text={`“It was a process which I felt really pushed me in the right
            direction in regards to my thoughts about the career path”`}
          image={bookDisabled}
          name={"Samuel O. 17 y.o."}
          position={"Career path in Psychology"}
        />
        <AuthorStory
          text={`I liked “how friendly everyone at Career Scoops is and how
          communicative they are”`}
          image={bookEnabled}
          name={"Sebastian R. 18 y.o."}
          position={"Career path in Detectives and Criminal Investigators"}
          hasWhiteBorder={true}
        />
        <div className="Students-row__mock__2" />
      </div>
      <div className="Students-row">
        <div className="Students-row__mock__3" />
        <AuthorStory
          text={`I liked “the opportunity to ask a real professional real questions”`}
          image={bookEnabled}
          name={"Faerie M. 19 y.o."}
          position={"Career path in Clinical Nursing"}
        />
        <AuthorStory
          text={`“It gave me a better perspective on that [professional athlete]
          career”`}
          image={bookDisabled}
          name={"Kevin S. 18 y.o."}
          position={"Career path in Sports Competitors and Athletes"}
          hasWhiteBorder={true}
        />
        <div className="Students-row__mock__4" />
      </div>
      <div className="Students-row">
        <div className="Students-row__mock__5" />
        <AuthorStory
          text={`“I loved everything about it”`}
          image={bookDisabled}
          name={"Daphne P. 17 y.o."}
          position={"Career Path in Marketing"}
          hasWhiteBorder={true}
        />
        <AuthorStory
          text={`I liked “that you get to experiment with the careers”`}
          image={bookEnabled}
          name={"Rose M. 17 y.o."}
          position={"Career path in Criminal Justice"}
        />
        <div className="Students-row__add" onClick={openModal}>
          <div className="Students-row__add__img">
            <img src={pencil} alt="" />
          </div>
          <p className="Students-row__add__text">Share your story with us!</p>
        </div>
        <div className="Students-row__mock__6" />
      </div>
      <InviteFriendModal
      isOpened={isModalOpened}
      closeModal={closeModal}
      title="Contact us"
      text="Please feel free to leave us a detailed message below, and we'll make sure to get back to you promptly. You can also reach us by phone at (407) 205-7872 or email us directly at info@career-scoops.com"
      leftBtnText="Cancel"
      rightBtnText="Send"
      img={Envelope}
      />
    </section>
  );
};

export default StudentsStory;
