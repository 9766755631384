import React, { useState } from "react";
import { ReactComponent as Star } from "../../../../../../assets/Star.svg";
import classNames from "classnames";
import "./Star.scss";

const Stars = ({ arr, onChange,rate}) => {
  // const [hoverRating, setHoverRating] = useState(0);
  // const handleStarClick = (index) => {
  //   const newRating = index + 1;
  //   onChange(newRating);
  // };
  
  // const handleStarHover = (index) => {
  //   setHoverRating(index + 1);
  // };

  // const handleStarHoverLeave = () => {
  //   setHoverRating(0);
  // };

  return (
    <div className="stars"
    // onClick={(e)=>e.stopPropagation()}
    // onMouseLeave={handleStarHoverLeave}
    >
      {arr.map((_, index) => {
        const starIndex = index + 1;
        // const isFilled = starIndex <= (hoverRating || rating);
        return (
          <Star
            key={Math.random()}
            className={classNames(
              "stars__star",
              // {
              //   hovered: isFilled,
              // }
              {
                rated: starIndex <= rate
              }
            )}
            icon="star"
            // onClick={() => handleStarClick(index)}
            // onMouseEnter={() => handleStarHover(index)}
          />
        );
      })}
    </div>
  );
};

export default Stars;
