import { useState } from "react"


const useStory = () => {
    const [isModalOpened,setIsmodalOpened] = useState(false)
    const openModal = () => {
        setIsmodalOpened(true)
    }
    const closeModal = () => {
        setIsmodalOpened(false)
    }
  return {
   closeModal,
   openModal,
   isModalOpened
  }
}
export default useStory