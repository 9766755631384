import React, { useState } from "react";
import { Link } from "react-router-dom";
import Polygon from "../../../../assets/polygon.svg";

const SpeechBubble = ({altText, buttonLink, buttonText, floatSide, hasButton, speechText }) => {
    const linkStyle = {
        border: '1px solid #254365',
        borderRadius: '8px',
        color: '#254365',
        float: floatSide,
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '24px',
        padding: '10px 24px',
    };

    return (
        <div>
            <img src={Polygon} alt={altText} />
            <p>{speechText}</p>
            {hasButton ? (
            <div style={{padding: '20px 0 8px 0'}}>
                <Link style={linkStyle} to={buttonLink}>{buttonText}</Link>
            </div>
            ) : <></>}
        </div>
    )
}

export default SpeechBubble;