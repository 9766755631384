import React from "react";
import { useDragLayer } from "react-dnd";
import useWindowWidth from "../../hooks/useWindowWidth"

const styles = {
  display: "inline-block",
  transform: "rotate(-7deg)",
  WebkitTransform: "rotate(-7deg)",
};

const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
};
function getItemStyles(initialOffset, currentOffset, isSnapToGrid, props) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }
  let { x, y } = currentOffset;
  const transform =
    props.scenarioId === 4
      ? `translate(${x}px, ${y}px) rotate(${-9.956}deg)`
      : `translate(${x}px, ${y}px) rotate(${-43.124}deg)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}
export const CustomDragLayer = (props) => {
  const {isMobile} = useWindowWidth()
  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));
  function renderItem() {
    return props.scenarioId === 4 ? (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          borderRadius: '8px',
          border: '1px solid var(--accent-brand, #07D3C4)',
        }}
      >
        <img
          src={item.image}
          alt="cupImage"
          style={{
            width: isMobile ? "92px":"120px",
            height: isMobile ? "92px":"120px",
          }}
        />

      </div>
    ) : (
      <div
        style={{
          ...styles,
          transform: "rotate(-43.124deg)",
          display: "inline-flex",
          padding: "5px 12px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          borderRadius: "8px",
          border: "1px solid var(--accent-brand, #07D3C4)",
          background: "var(--accent-main, #20528B)",
        }}
      >
        <div
          style={{
            color: "var(--text-color-main, #FEFEFE)",
            textAlign: "center",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "22px",
          }}
        >
          {item.text}
        </div>
      </div>
    );
  }
  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(
          initialOffset,
          currentOffset,
          props.snapToGrid,
          props
        )}
      >
        {renderItem()}
      </div>
    </div>
  );
};