import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import {
  authIsAuthenticated,
  authUser,
} from "../redux/selectors/authSelectors";
import { UserSettings } from "../constants/AppConstants";
import { initGetProfile, initPricingPlan } from "../redux/actions";

const useNavigation = (isNavigation) => {
  const { results, isLoading } = useSelector((state) => state.scenariosReducer);
  const profileMatch = useMatch("/profile/*");
  const pricingPlans = useSelector((state) => state.pricingPlanReducer.plans);
  const dispatch = useDispatch();
  const storageUser = useSelector((state) => authUser(state));
  const { role } = storageUser || {};
  const isProfileChildRoute =
    profileMatch !== null && profileMatch.params["*"] !== "";
  const pages = UserSettings.student;
  const isAuth = useSelector((state) => authIsAuthenticated(state));
  const pageName =
    pages.find((el) => el.route === profileMatch?.params["*"])?.department ||
    (function () {
      if (
        profileMatch?.params["*"] === "EditProfile" ||
        profileMatch?.params["*"] === "editPassword"
      ) {
        return "Profile Info";
      }
      if (profileMatch?.params["*"] === "changePhoto") {
        return "Change Profile Picture";
      }
    })() ||
    results?.find(
      (el) => el.id === Number(profileMatch.params["*"].split("/").pop())
    )?.text;
  const [checked, setChecked] = useState(false);

  function getPricingPlanInfo() {
    if (role !== "Mentor") {
      if (user?.pricing_plan) {
        return (
          <h3>
            {pricingPlans.length
              ? pricingPlans.filter((plan) => plan.id === user?.pricing_plan)[0]
                  .title
              : ""}
          </h3>
        );
      }
      return <h3>Free Member</h3>;
    } else {
      return <h3 className="mentor">Community Partner</h3>;
    }
  }

  function getIsPaidPlan() {
    if (role !== "Mentor") {
      if (
        user?.pricing_plan &&
        pricingPlans.filter((plan) => plan.id === user?.pricing_plan)[0]?.price
      ) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  }
  useEffect(() => {
    if (isAuth && isNavigation) {
      dispatch(initPricingPlan());
      dispatch(initGetProfile());
    }
  }, [isAuth, isNavigation]);

  useEffect(() => {
    const checkScroll = () => {
      document.body.style.overflowY = checked ? "hidden" : "auto";
    };

    checkScroll();
  }, [checked]);

  const handleChangeInp = (e) => {
    setChecked(e.target.checked);
  };

  const navLinkStyles = ({ isActive }) => ({
    color: isActive ? "#07D3C4" : "#FEFEFE",
  });

  const handleChangePage = () => {
    if (!window.matchMedia("(max-width: 768px)").matches) return;

    setChecked(false);
  };

  const user = useSelector((state) => state?.profileReducer?.profile);
  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/profile");
  };
  const navigateBack = () => {
    navigate(-1);
  };
  return {
    checked,
    isAuth,
    user: {
      ...user,
      role,
    },
    isProfileChildRoute,
    pageName,
    handleChangeInp,
    handleChangePage,
    navLinkStyles,
    handleProfileClick,
    navigateBack,
    getPricingPlanInfo,
    getIsPaidPlan,
  };
};

export default useNavigation;
