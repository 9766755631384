import React from "react";
import classnames from "classnames";
import { DateHighToLow, DateLowToHigh } from "./components";
import useMyFeedBack from "../../../../hooks/useMyFeedBack";
import "./MyFeedBack.scss";

const MyFeedBack = () => {
  const {
      activeBtnText,
      changeBtnText,
      data,
      navigate,
      role
     } = useMyFeedBack();
  return (
        <>
          <div className="MyFeedBack-cont">
            <button
              onClick={() => {
                changeBtnText("Date high to low");
              }}
              className={classnames("MyFeedBack-cont__btn", {
                activeBtn: activeBtnText === "Date high to low",
              })}
            >
              <p
                className={classnames("MyFeedBack-cont__btn__text", {
                  activeBtnText: activeBtnText === "Date high to low",
                })}
              >
                Date high to low
              </p>
            </button>
            <button
              onClick={() => {
                changeBtnText("Date low to high");
              }}
              className={classnames("MyFeedBack-cont__btn", {
                activeBtn: activeBtnText === "Date low to high",
              })}
            >
              <p
                className={classnames("MyFeedBack-cont__btn__text", {
                  activeBtnText: activeBtnText === "Date low to high",
                })}
              >
                Date low to high
              </p>
            </button>
          </div>
          {activeBtnText === "Date high to low" ? (
            <DateHighToLow 
            data={data?.sort((a, b) => new Date(b.created_at) -new Date( a.created_at))} 
            role={role}
            />
          ) : (
            <DateLowToHigh 
            data={data?.sort((a, b) => new Date(a.created_at) -new Date( b.created_at))} 
            role={role}
            />
          )}
        </>  
  );
};

{/* <div className="emptyMyFeedback-cont">
<p className="emptyMyFeedback-cont__text">
  You don't have any reviews yet
</p>
<button className="emptyMyFeedback-cont__btn" onClick={()=>{
  navigate("/scenario/0")
}}>
  <p className="emptyMyFeedback-cont__btn__text">Start Test Now</p>
</button>
</div> */}

export default MyFeedBack;
