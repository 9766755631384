import React, { useEffect } from "react";
import leftCutSquare from "../assets/cut-leftTriangle.svg";
import rightCutSquare from "../assets/cut-rightTriangle.svg";
import { useDispatch, useSelector } from "react-redux";
import { initPricingPlan } from "../redux/actions";
import Sundae from "../assets/Sundae.svg";
const useMyPlan = () => {
  const dispatch = useDispatch();
  const pricingPlans = useSelector(state => state.pricingPlanReducer.plans);
  const user = useSelector((state) => (state?.profileReducer?.profile))
  useEffect(() => {
    dispatch(initPricingPlan())
  }, [])
  const renderFoodItems = (arr) => {
    const res = arr.map((el) => {
      return (
        <div className="myPlanCont__item" key={el.id}>
          <div className="myPlanCont__item__priceCont">
            <div className="myPlanCont__item__priceCont__imgWrapper">
              <img
                src={el?.whiteImage || el.image || Sundae}
                alt={el.title}
                className="myPlanCont__item__priceCont__imgWrapper__img"
              />
            </div>
            <div className="myPlanCont__item__priceCont__priceInfo">
              <p className="myPlanCont__item__priceCont__priceInfo__title">
                {el.title}
              </p>
              <p className="myPlanCont__item__priceCont__priceInfo__price">
                {el.price}
                <span className="myPlanCont__item__priceCont__priceInfo__price__index">
                  {el.duration && ` / ${el.duration}`}
                </span>
              </p>
            </div>
          </div>
          <div className="myPlanCont__item__descriptionCont">
            <p className="myPlanCont__item__descriptionCont__title">
              {el.title} includes
            </p>
            <ul className="myPlanCont__item__descriptionCont__textCont">
              {renderDescription(el.description.split('\r\n'))}
            </ul>
          </div>
        </div>
      );
    });
    return res;
  };

  const renderDescription = (arr) => {
    const res = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i + 1]?.includes("100%")) {
        res.push(
          <React.Fragment key={Math.random()}>
            <li className="myPlanCont__item__descriptionCont__textCont__text lessMargin">
              {arr[i]}
              <p className="myPlanCont__item__descriptionCont__textCont__text__hundreedPercent">
                {arr[i + 1]}
              </p>
            </li>
            <li className="myPlanCont__item__descriptionCont__textCont__text__ComingSoon">
              <img src={leftCutSquare} alt="leftCutSquare" />
              <p className="myPlanCont__item__descriptionCont__textCont__text__ComingSoon__text">
                COMING SOON
              </p>
              <img src={rightCutSquare} alt="rightCutSquare" />
            </li>
          </React.Fragment>
        );
        i += 2;
      }
      if (i === arr.length - 1) {
        res.push(
          <li
            className="myPlanCont__item__descriptionCont__textCont__text lessMargin"
            key={Math.random()}
          >
            {arr[i]}
          </li>
        );
      } else {
        res.push(
          <li
            className="myPlanCont__item__descriptionCont__textCont__text"
            key={Math.random()}
          >
            {arr[i]}
          </li>
        );
      }
    }
    return res;
  };

  return {
    pricingPlans,
    user,
    renderFoodItems
  };
};

export default useMyPlan;
