import { createSelector } from "reselect";
import {
  getJWTFromLocalStorage,
  getUSERFromLocalStorage,
} from "../../common/localStorage";

export const authState = (state) => state.authReducer;
export const authIsAuthenticated = createSelector(
  authState,
  (auth) => auth.isAuthenticated || getJWTFromLocalStorage()
);

export const authIsLoading = createSelector(
  authState,
  (auth) => auth.isLoading
);

export const authUser = createSelector(
  authState,
  (auth) => auth.user || getUSERFromLocalStorage()
);
