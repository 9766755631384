import * as actionTypes from "../actionTypes";

const initialState = {
  error: null,
};

const quotesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_QUOTES:
      return {
        ...state,
      };
    case actionTypes.GET_QUOTES_SUCCESS:
      return {
        ...state,
        quotes: payload?.results,
      };
    case actionTypes.GET_QUOTES_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default quotesReducer
