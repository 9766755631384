import React from "react";

import "./StatusBar.scss";

const StatusBar = ({ activeIndex, totalAmount }) => {
  return (
    <div className="StatusBar">
      {[...new Array(activeIndex)].map((_, index) => (
        <div key={index} className="active" />
      ))}
      {[...new Array(totalAmount - activeIndex)].map((_, index) => (
        <div key={index} className="disactive" />
      ))}
    </div>
  );
};

export default StatusBar;
