import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initSignUp, signupSuccessReset } from "../redux/actions/authActions";
import { initTags, initIndustry } from "../redux/actions";

const useMentorSignUp = () => {
  const [tags, setTags] = useState([]);
  const availableTags = useSelector((state) => state.tagsReducer.tags);
  const industries = useSelector((state) => state.industryReducer.industries);
  const isSaving = useSelector((state) => state.authReducer.isSaving);
  const signupSuccess = useSelector((state) => state.authReducer.signupSuccess);
  const [selectedIndustry, setSelectedIndustry] = useState({});
  const [page, setPage] = useState(1);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("Please input email address");
  const [firstError, setFirstError] = useState("");
  const [lastError, setLastError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [showError, setShowError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [termsCheck, setTermsCheck] = useState(false);
  const [shareDataCheck, setShareDataCheck] = useState(false);
  const [linkedIn, setLinkedIn] = useState("");
  const [title, setTitle] = useState("");
  const [isSuccessVisible, changeSuccessVisibility] = useState(false);
  const errorFromBackend = useSelector((state) => state?.authReducer?.error);
  const [isErroModalOpened, setIsErrorModalOPened] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const getErrorFromBackend = () => {
    let result = [];
    if (typeof errorFromBackend === "object" && errorFromBackend !== null) {
      for (let key in errorFromBackend) {
        result.push(
          <p
            key={Math.random()}
            style={{
              textAlign: "left",
            }}
          >{`${key}: ${errorFromBackend[key][0]}`}</p>
        );
      }
    }
    return result;
  };
  const closeErrorModal = () => {
    setIsErrorModalOPened(false);
    dispatch({
      type: "SIGNUP_FAIL",
      payload: {
        error: null,
      },
    });
  };
  useEffect(() => {
    changeSuccessVisibility(signupSuccess);
  }, [signupSuccess]);

  useEffect(() => {
    if (errorFromBackend) {
      setIsErrorModalOPened(true);
    }
  }, [errorFromBackend]);

  useEffect(() => {
    if (!firstName) {
      setFirstError("Please enter valid first name");
    }

    if (!lastName) {
      setLastError("Please enter valid last name");
    }
    if (!phone) {
      setPhoneError("Please enter phone");
    }
  }, [showError]);

  const checkEmail = (e) => {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e)) {
      setError("Please enter a valid email address.");
    } else {
      setError(null);
    }
    setShowError(false);
    setEmail(e);
    return;
  };

  const checkFirstName = (e) => {
    if (!firstName) {
      setFirstError("Please enter a valid first name");
    } else {
      setFirstError(null);
    }
    setShowError(false);
    setFirstName(e);

    return;
  };

  const checkLastName = (e) => {
    if (!lastName) {
      setLastError("Please enter a valid last name");
    } else {
      setLastError(null);
    }
    setShowError(false);
    setLastName(e);

    return;
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setSelectedIndustry(industries[0]);
  }, [industries]);
  useEffect(() => {
    dispatch(signupSuccessReset());
    dispatch(initTags());
    dispatch(initIndustry());
  }, []);

  const removeTag = (index) => {
    const filteredTags = tags.filter((_item, i) => i !== index);
    setTags(filteredTags);
  };
  const addTags = (e, v) => {
    setTags([...tags, Number(e.target.value)]);
  };

  const handleSignUp = () => {
    if (!password) {
      setPasswordError("Please enter password");
      return;
    }
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }
    dispatch(
      initSignUp({
        email,
        role: "Mentor",
        first_name: firstName,
        last_name: lastName,
        password,
        phone,
        address,
        zipCode,
        tags,
        shareDataCheck,
        Industry: selectedIndustry?.id,
        social_url: linkedIn,
        title,
      })
    );
  };
  const handleSelectedIndustry = (text) => {
    const newSelectedIndustry = industries.find((el) => el.name === text);
    setSelectedIndustry(newSelectedIndustry);
  };
  return {
    isSuccessVisible,
    changeSuccessVisibility,
    addTags,
    tags,
    isSaving,
    availableTags,
    removeTag,
    page,
    setPage,
    email,
    showError,
    setShowError,
    setEmail: checkEmail,
    firstName,
    setFirstName: checkFirstName,
    lastName,
    setLastName: checkLastName,
    password,
    error,
    firstError,
    lastError,
    setPassword,
    phone,
    setPhone,
    setAddress,
    setZipCode,
    handleSignUp,
    industries,
    handleSelectedIndustry,
    selectedIndustry,
    termsCheck,
    setTermsCheck,
    shareDataCheck,
    setShareDataCheck,
    linkedIn,
    setLinkedIn,
    title,
    setTitle,
    getErrorFromBackend,
    closeErrorModal,
    isErroModalOpened,
    confirmPassword,
    setConfirmPassword,
    passwordError,
    setPasswordError,
    phoneError,
  };
};

export default useMentorSignUp;
