import React, { useEffect } from "react";
import useScenarioQuestions from "../../hooks/useScenarioQuestions";
import { DndProvider } from  'react-dnd-multi-backend'
import { HTML5Backend } from "react-dnd-html5-backend";
import { HTML5toTouch } from 'rdndmb-html5-to-touch' 
import { CustomDragLayer } from "../ScenarioQuestions/CustomDragLayer";

import "./Scenario.scss";
import "../ScenarioQuestions/ScenarioQuestions.scss";
import { initScenarios } from "../../redux/actions";
import { useDispatch } from "react-redux";
const Scenario = () => {
  const dispatch = useDispatch();
  const { scenarioId, mappingArray } = useScenarioQuestions();
  useEffect(() => {
    dispatch(initScenarios())
  }, [])
  return (
      mappingArray[scenarioId] && mappingArray[scenarioId]?.component && <DndProvider options={HTML5toTouch} >
        <section className={mappingArray[scenarioId]?.className}>
          {mappingArray[scenarioId]?.component}
        </section>
        <CustomDragLayer scenarioId={mappingArray[scenarioId].scenarioId} />
      </DndProvider>
  );
};

export default Scenario;
