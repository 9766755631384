import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import axiosClient from "../common/api/request";


const useFooter = () => {
    const { pathname } = useLocation();
    const [isModalOpened,setIsmoadlOpened] = useState(false)
    const [text,setText] = useState("")
    const [title,setTitle] = useState("")
    const [email,setEmail] = useState("")
    const [isSuccessModalOpened,setIsSuccessModalOpened] = useState(false)
    const [emailError, setEmailError] = useState("");
    const [showError, setShowError] = useState(false);
    const closeModal = () => {
        setIsmoadlOpened(false)
    }
    const openModal = (bookModaltitle,bookModalText) => {
        setTitle(bookModaltitle)
        setText(bookModalText)
        setIsmoadlOpened(true)
    }
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };
    
      useEffect(() => {
        const linksToScrollTop = ["/about", "/stories", "/faq", "/terms", "/privacypolicy"];
        if (linksToScrollTop.some((link) => pathname.includes(link))) {
          scrollToTop();
        }
      }, [pathname]);
      const handleInput = (e) => {
        setEmail(e.target.value)
      }
      useEffect(()=>{
        if(emailError){
          setEmailError("")
          setShowError(false)
        }
      },[email])
      const subsCribe = () => {
        axiosClient
        .post("subscriptions/", {
          email
        })
        .then(() => {
           openSuccessModal()
           setEmail("")
        })
        .catch(() => {
        //  console.log(response.response.data.email[0])
        closeSuccessModal()
        });
      }
      const checkEmail = (e) => {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e)) {
          setEmailError("Please enter a valid email address.");
          setShowError(true);
        } else {
          setEmailError(null);
          setShowError(false);
          subsCribe()
        }
      };
      const openSuccessModal = () => {
        setIsSuccessModalOpened(true)
      }
      const closeSuccessModal = () => {
        setIsSuccessModalOpened(false)
      }
    return {
        text,
        title,
        isModalOpened,
        closeModal,
        openModal,
        handleInput,
        email,
        subsCribe,
        isSuccessModalOpened,
        openSuccessModal,
        closeSuccessModal,
        checkEmail,
        showError,
        emailError
    }
}
export default useFooter