import * as actionTypes from "../actionTypes";

export const initMentors = (mentors) => ({
  type: actionTypes.INIT_MENTORS,
  payload: {
    ...mentors,
  },
});

export const getMentors = () => ({
  type: actionTypes.GET_MENTORS,
});

export const mentorsSuccess = (payload) => ({
  type: actionTypes.MENTORS_SUCCESS,
  payload,
});

export const mentorError = (error) => ({
  type: actionTypes.MENTORS_FAIL,
  error,
});


export const initIndustry = (industries) => ({
  type: actionTypes.INIT_INDUSTRY,
  payload: {
    ...industries,
  },
});

export const getIndustries = () => ({
  type: actionTypes.GET_INDUSTRIES,
});

export const getIndustriesSuccess = (payload) => ({
  type: actionTypes.GET_INDUSTRIES_SUCCESS,
  payload,
});

export const getIndustriesError = (error) => ({
  type: actionTypes.GET_INDUSTRIES_FAIL,
  error,
});
