import React, { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import classnames from "classnames";
// import RecommendationRetrievalModal from "../../modals/RecommendationRetrievalModal";
import { FAQ } from "../../constants/AppConstants";

import "./Faq.scss";

const Faq = () => {
  const { pathname } = useLocation();
  const [questions, setQuestions] = useState(FAQ["careerexplorer"]);

  // const [isRetrievalModalOpen, setRetrievalModalOpen] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setRetrievalModalOpen(true);
  //   }, 0); // 90 seconds

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  return (
    <section className="Faq">
      {/* <RecommendationRetrievalModal
        isOpen={isRetrievalModalOpen}
        onClose={() => setRetrievalModalOpen(false)}
      /> */}
      <div className="Faq-navigation">
        <h2>Frequently asked questions</h2>
        <div className="Faq-navigation__tabs">
          <Link
            to="careerexplorer"
            onClick={() => setQuestions(FAQ["careerexplorer"])}
            className={classnames("Faq-navigation__tabs__item", {
              isActiveFaq:
                pathname === "/faq" || pathname === "/faq/careerexplorer",
            })}
          >
            Career Explorer
          </Link>
          <Link
            to="communitypartner"
            onClick={() => setQuestions(FAQ["communitypartner"])}
            className={classnames("Faq-navigation__tabs__item", {
              isActiveFaq: pathname === "/faq/communitypartner",
            })}
          >
            Community Partner
          </Link>
        </div>
      </div>
      <Outlet context={[questions, setQuestions]} />
    </section>
  );
};

export default Faq;
