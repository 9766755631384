import useEditPassword from "../../../../hooks/useEditPassword";
import openEye from "../../../../assets/passEyeOpen.svg";
import closedEye from "../../../../assets/passEyeClose.svg";
import InterrogativeImage from "../../../../assets/interrogativeImage.png";
import Error from "../../../../assets/error.svg";
import { CancelModal } from "../../../../modals";
import "./EditPassword.scss";

const EditPassword = () => {
  const {
    passwordObj,
    confirmPasswordObj,
    isCloseModalOpened,
    handleConfirmPasswordChange,
    handlePasswordChange,
    handlePasswordEye,
    handleConfirPasswordEye,
    openCloseModal,
    closeCloseModal,
    checkPasword,
    isErrorVisible,
    confirmationError,
    loseChanges
  } = useEditPassword();

  return (
    <div className="EditPasswordCont">
      <p className="EditPasswordCont__title">Edit Password</p>
      <div className="EditPasswordCont__content">
        <div className="EditPasswordCont__content__items">
          <p className="EditPasswordCont__content__items__text">Password</p>
          <div className="EditPasswordCont__content__items__inpWrapper">
            <input
              type="text"
              className="EditPasswordCont__content__items__inpWrapper__inp"
              placeholder="Password"
              value={
                passwordObj.isEyeClosed
                  ? passwordObj.maskedPassword
                  : passwordObj.password
              }
              onChange={handlePasswordChange}
            />
            <img
              src={passwordObj.isEyeClosed ? closedEye : openEye}
              alt="closedEye"
              className="EditPasswordCont__content__items__inpWrapper__eye"
              onClick={handlePasswordEye}
            />
          </div>
        </div>
        <div className="EditPasswordCont__content__items">
          <p className="EditPasswordCont__content__items__text">
            Confirm New Password
          </p>
          <div className="EditPasswordCont__content__items__inpWrapper">
            <input
              type="text"
              className="EditPasswordCont__content__items__inpWrapper__inp"
              placeholder="New Password"
              value={
                confirmPasswordObj.isEyeClosed
                  ? confirmPasswordObj.maskedPassword
                  : confirmPasswordObj.password
              }
              onChange={handleConfirmPasswordChange}
            />
            <img
              src={confirmPasswordObj.isEyeClosed ? closedEye : openEye}
              alt="closedEye"
              className="EditPasswordCont__content__items__inpWrapper__eye"
              onClick={handleConfirPasswordEye}
            />
          </div>
        </div>
      </div>
      {isErrorVisible ? (
        <div className="confirmationError">
          <img src={Error} alt="Error" />
          <p className="confirmationError__Text">{confirmationError}</p>
        </div>
      ) : null}
      <div className="EditPasswordCont__btnCont">
        <button
          className="EditPasswordCont__btnCont__btnCancel"
          onClick={openCloseModal}
        >
          <p className="EditPasswordCont__btnCont__btnCancel__text">Cancel</p>
        </button>
        <button
          disabled={isErrorVisible}
          className="EditPasswordCont__btnCont__btnSaveChange"
          onClick={() => {
            checkPasword();
          }}
        >
          <p className="EditPasswordCont__btnCont__btnSaveChange__text">
            Save Changes
          </p>
        </button>
      </div>
      <CancelModal
        image={InterrogativeImage}
        title="Are you sure you want to Cancel?"
        text="Your changes will not be saved."
        leftBtnText="No"
        rightBtnText="Yes"
        isOpened={isCloseModalOpened}
        close={closeCloseModal}
        removeChanges={loseChanges}
      />
    </div>
  );
};

export default EditPassword;
