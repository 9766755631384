import React from "react";
import Human from "../../../../assets/Human.svg";
import AddPlus from "../../../../assets/addImage.svg";
import useUpdateImage from "../../../../hooks/useUpdateImage";
import ChangeAvatar from "../../../../assets/ChangeAvatarIcon.svg";
import classNames from "classnames";
import { CancelModal } from "../../../../modals";
import InterrogativeImage from "../../../../assets/interrogativeImage.png";

import "./UpdateImage.scss";

const UpdateImage = () => {
  const {
    fileInputRef,
    choosenImage,
    isModalOpened,
    handleIconClick,
    handleFileInputChange,
    openModal,
    closeModal,
    loseChanges,
    saveChanges
  } = useUpdateImage();
  return (
    <div className="UpdateImageCont">
      <div className="UpdateImageCont__imgWrapper">
        <img
          src={choosenImage ? choosenImage : Human}
          alt="Human"
          className={classNames("UpdateImageCont__imgWrapper__human", {
            mainImg: choosenImage && !choosenImage.includes("Human"),
          })}
        />
        <img
          src={choosenImage ? ChangeAvatar : AddPlus}
          alt="AddPlus"
          className="UpdateImageCont__imgWrapper__addImage"
          onClick={handleIconClick}
        />
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileInputChange}
        />
      </div>
      <div className="UpdateImageCont__btnCont">
        <button className="UpdateImageCont__btnCont__cancel" onClick={openModal}>
          <p className="UpdateImageCont__btnCont__cancel__text">Cancel</p>
        </button>
        <button className="UpdateImageCont__btnCont__save" onClick={saveChanges}>
          <p className="UpdateImageCont__btnCont__save__text">Save Changes</p>
        </button>
      </div>
      <CancelModal
        isOpened={isModalOpened}
        close={closeModal}
        image={InterrogativeImage}
        title="Are you sure you want to Cancel?"
        text="Your changes will not be saved."
        leftBtnText="No"
        rightBtnText="Yes"
        removeChanges={loseChanges}
      />
    </div>
  );
};

export default UpdateImage;
