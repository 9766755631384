import * as actionTypes from "../actionTypes";

export const initGetSessions = () => ({
  type: actionTypes.INIT_GET_SESSIONS,
});

export const getSessions = () => ({
    type: actionTypes.GET_SESSIONS,
})

export const getSessionsSuccess = (payload) => ({
    type: actionTypes.GET_SESSIONS_SUCCESS,
    payload,
  });

  export const getSessionsError = (error) => ({
    type: actionTypes.GET_SESSIONS_ERROR,
    error,
  });