import AuthService from "../../services/authService";
import * as actionTypes from "../actionTypes";

const initialState = {
  access: "",
  refresh: "",
  isAuthenticated: false,
  signupSuccess: false,
  isLoading: false,
  user: null,
  error:null
};

const authHandler = new AuthService(initialState);

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SIGNIN:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.SIGNIN_SUCCESS:
      return authHandler.signInSuccess(state, payload);
    case actionTypes.SIGNIN_FAIL:
      return authHandler.signInFail(state, payload);
    case actionTypes.SIGN_OUT:
      return authHandler.signOut(state);
    case actionTypes.SIGNUP_SUCCESS_RESET:
      return {
        ...state,
        signupSuccess: false
      }
    case actionTypes.SIGNUP:
      return {
        ...state,
        signupSuccess: false,
        isSaving: true
      }
    case actionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        signupSuccess: true,
        isSaving: false
      }
      case actionTypes.SIGNUP_FAIL:
        return {
          ...state,
          error:payload.error,
          isSaving: false
        }
    default:
      return state;
  }
};

export default authReducer;
