import { useEffect, useState } from "react";
import { LocalStorage } from "../common/localStorage";

const useQuestion6 = (data) => {
  const storedAnswers = LocalStorage.getFromLocalStorage('scenarios');
  const [answers, setAnswers] = useState(data.map(item => { return { id: item.id, value: (storedAnswers && storedAnswers[item.id]) || null } }));
  useEffect(() => {
    answers.forEach(item => LocalStorage.updateLocalStorage('scenarios', { [item.id]: item.value }))
  }, [answers])
  const setAnswer = (id, value) => {
    setAnswers((prev) => prev.map((element, i) => {
      return {
        ...element,
        value: element.id === id? value: element.value
      };
    }))
  }
  return {
    answers,
    setAnswer
  }
}

export default useQuestion6;