import * as actionTypes from "../actionTypes";
const initialState = {
  tags: [],
  error: null,
};
const tagsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_TAGS:
      return {
        ...state,
        tags: []
      };
    case actionTypes.GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: payload,
      };
    case actionTypes.GET_TAGS_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};
export default tagsReducer;
