import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";
import { getTags, getTagsError, getTagsSuccess } from "../actions";

export function* tagsSaga({ payload }) {
  yield put(getTags());
  try {
    const response = yield call(routes.getTags, payload);
    yield put(getTagsSuccess(response));
  } catch (error) {
    yield put(getTagsError(error));
  }
}
