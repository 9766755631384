import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";
import { getRecommendations, getRecommendationsSuccess, getRecommendationsError } from "../actions";

export function* getRecommendationsSaga({ params }) {
  yield put(getRecommendations())
  try {
    const response = yield call(routes.getRecommendations, params);
    yield put(getRecommendationsSuccess(response));
  } catch (error) {
    yield put(getRecommendationsError(error));
  }
}