import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { X } from "lucide-react";
import "./BaseModal.scss";

const modalRoot = document.getElementById("modal-root");

const BaseModal = ({ error, success, title, isOpen, onClose, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="modal">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <X strokeWidth={1} size={36} />
        </button>
        {children}
      </div>
    </div>,
    modalRoot
  );
};

export default BaseModal;
