import * as actionTypes from "../actionTypes";

export const initTags = (userCredentials) => ({
    type: actionTypes.INIT_TAGS,
    payload: {
      ...userCredentials,
    },
  });
  
  export const getTags = () => ({
    type: actionTypes.GET_TAGS,
  });

  export const getTagsSuccess = (payload) => ({
    type: actionTypes.GET_TAGS_SUCCESS,
    payload,
  });
  
  export const getTagsError = (error) => ({
    type: actionTypes.GET_TAGS_ERROR,
    error,
  });
  