import { useEffect, useState } from "react";

const useThinking = () => {
  const [index, setindex] = useState(0);
  const texts = [
    `Dwayne "The Rock" Johnson, before becoming a professional wrestler and
    actor, had dreams of playing professional football. However, after a
    number of injuries and being cut from the Canadian Football League, he
    had to reevaluate his career path and eventually found success in the
    entertainment industry.`,
    `Ellen DeGeneres, one of the most successful comedians and talk show hosts of our time, actually started her career as an administrative assistant. It wasn't until she began doing stand-up comedy on the side that she realized her true passion and started pursuing it full-time.`,
    `Arnold Schwarzenegger, the bodybuilder turned actor turned politician, originally wanted to be a soccer player. However, after watching a bodybuilding competition, he became obsessed with the sport and eventually became a seven-time Mr. Olympia champion. He then went on to pursue a successful career in acting and later became the Governor of California.`,
    'Before writing Harry Potter, J.K. Rowling worked as a secretary and then as a researcher for Amnesty International. She didn’t discover her passion for writing until later in life, after facing many personal and financial struggles. Rowling began writing Harry Potter while on welfare, and after numerous rejections from publishers, she finally found success.',
    'Before co-founding Netflix, Reed Hastings worked as a door-to-door vacuum cleaner salesman and later as a software engineer. After being frustrated by late fees on a movie rental, he pivoted from software to the entertainment industry, developing the idea that would become Netflix.',
    'Before becoming the most decorated gymnast in history, Simone Biles faced significant challenges, including spending time in foster care. Originally, she didn’t start gymnastics until later than most elite gymnasts, but her talent quickly stood out. Despite these early setbacks and starting behind her peers, Simone’s dedication and drive helped her rise to the top of the sport, becoming a multi-time Olympic and World Champion.'
  ]

  const text = texts[index % 6]
  useEffect(() => {
    const timer = setTimeout(() => setindex(old => old + 1), 15000)
    return () => clearTimeout(timer)
  }, [index])
  return {
    text
  }
}

export default useThinking;