import React from "react";
import ScenarioFooter from "../../../../components/ScenarioFooter/ScenarioFooter";
import Biscuit from "../../../../assets/Biscuit.png";
import StatusBar from "../../../../components/StatusBar/StatusBar";
import classNames from "classnames";
import "./Scenario3.scss";
import useWindowWidth from "../../../../hooks/useWindowWidth";
const Scenario3 = ({
  back,
  next,
  sugestionText,
  isGreen,
  answer,
  setAnswer,
  data,
  total,
  progress,
  handleSubmitResults
}) => {
  const raterange = [1, 2, 3, 4, 5];
  const { isMobile } = useWindowWidth()

  return (
    <section className="Scenario3">
      <div className="Scenario3__BiscuitPart">
        {!isMobile ? <div className="Scenario3__BiscuitPart__Imgwrapper">
          <img
            src={Biscuit}
            alt="Biscuit"
            className="Scenario3__BiscuitPart__Imgwrapper__biscuit"
          />
        </div> : null}
        <div className="Scenario3__BiscuitPart__scenario">
          <div className="Scenario3__BiscuitPart__scenario__textWrapper">
            <img src={Biscuit} alt="Biscuit" className={classNames("Scenario3__BiscuitPart__scenario__textWrapper__img", {
              none: !isMobile
            })} />
            <p className="Scenario3__BiscuitPart__scenario__textWrapper__title">
              SCENARIO 3
            </p>
          </div>
          <div className="Scenario3__BiscuitPart__scenario__advises">
            <p className="Scenario3__BiscuitPart__scenario__advises__text">
              {data.description}
            </p>
            <StatusBar activeIndex={+progress} totalAmount={total} />
          </div>
          <div className="Scenario3__BiscuitPart__scenario__ratingRange-part">
            <p className="Scenario3__BiscuitPart__scenario__ratingRange-part__text">
              {sugestionText}
            </p>
            <div className="Scenario3__BiscuitPart__scenario__ratingRange-part__sliderCont">
              {isMobile ? <div className="Scenario3__BiscuitPart__scenario__ratingRange-part__sliderCont__textPart">
                <p className="Scenario3__BiscuitPart__scenario__ratingRange-part__sliderCont__leftText">
                  Not at all likely
                </p>
                <p className="Scenario3__BiscuitPart__scenario__ratingRange-part__sliderCont__rightText">
                  Extremely likely
                </p>
              </div> : null}
              <p className={classNames("Scenario3__BiscuitPart__scenario__ratingRange-part__sliderCont__leftText", {
                none: isMobile
              })}>
                Not at all likely
              </p>
              <div className="Scenario3__BiscuitPart__scenario__ratingRange-part__sliderCont__rateCont">
                <div className="Scenario3__BiscuitPart__scenario__ratingRange-part__sliderCont__rateCont__circles">
                  {raterange.map((el, ind) => (
                    <div
                      className="Scenario3__BiscuitPart__scenario__ratingRange-part__sliderCont__rateCont__circles__circle"
                      key={ind}
                      value={el}
                    ></div>
                  ))}
                  <input
                    type="range"
                    className={classNames(
                      "Scenario3__BiscuitPart__scenario__ratingRange-part__sliderCont__rateCont__circles__inp",
                      {
                        "cneter-2": answer === 1,
                        "cneter-1": answer === 2,
                        "end-2": answer === 4,
                        "end-1": answer === 5,
                        slider_none: answer === null,
                      }
                    )}
                    id="Sceanrio-3Slider"
                    min={1}
                    max={5}
                    step={1}
                    value={answer ? answer : ""}
                    onClick={(e) => {
                      if (e.target.value === "3" && answer === null) {
                        setAnswer(3);
                      }
                    }}
                    onChange={(e) => {
                      setAnswer(Number(e.target.value));
                    }}
                  />
                </div>
                <div className="Scenario3__BiscuitPart__scenario__ratingRange-part__sliderCont__rateCont__num-cont">
                  {[1, 2, 3, 4, 5].map((el, ind) => (
                    <div
                      className="Scenario3__BiscuitPart__scenario__ratingRange-part__sliderCont__rateCont__num-cont__num"
                      key={ind}
                    >
                      {el}
                    </div>
                  ))}
                </div>
              </div>
              <p className={classNames("Scenario3__BiscuitPart__scenario__ratingRange-part__sliderCont__rightText", {
                none: isMobile
              })}>
                Extremely likely
              </p>
            </div>
          </div>
        </div>
      </div>
      <ScenarioFooter
        variation={"question"}
        handleSubmitResults={handleSubmitResults}
        buttons={{
          next: {
            highLightColor: isGreen,
            disabled: answer ? false : true,
            text: next,
          },
          back: { text: back },
        }}
      />
    </section>
  );
};
export default Scenario3;
