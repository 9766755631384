import React from "react";
import ScenarioFooter from "../../../../components/ScenarioFooter";

import "./Scenario1.scss";

const Scenario1 = ({src}) => {
  return (
    <section className="Scenario1">
      <div className="Scenario1-cont">
        <h1>SCENARIO 1</h1>
        <div>
          <video controls>
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <ScenarioFooter variation="scenario" where="1" />
    </section>
  );
};

export default Scenario1;
