import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useResults from "./useResults";
import SuccessImage from "../assets/success.png";
import PlainImage from "../assets/plainIco.png";
import StudentImage from "../assets/Student.png";
import AiRobotImage from "../assets/AiRobot.png";
import { useEffect, useState } from "react";
const MODAL_TYPE = {
  BOOK_VALIDATION: 3,
  AI_REPORT: 2,
  OCCUPATION_PROFILE: 1,
};
const modalInfo = {
  1: {
    title: 'Awesome!',
    image: PlainImage,
    text: `You'll be redirected to an external resource with all the details on this career outlook. Make sure to come back to Career Scoops to keep exploring and discovering more opportunities!`
  },
  2: {
    title: 'Ready!',
    image: SuccessImage,
    text: `Your AI Insights Report will open in a new tab! Be sure to dive into it to get your personalized insights and learn more about your career potential.`
  },
  3: {
    title: 'Let’s do this!',
    image: StudentImage,
    text: `Want to request a mentorship session with a professional in this career field? Click 'Okay' and we'll get the process started!`,
    text2: 'Note: Availability depends on your Career Scoops plan.'
  },
  4: {
    title: 'Let’s go!',
    image: AiRobotImage,
    text: `Launching your personalized AI Career Buddy! Chat live for instant career guidance and advice.`
  },
  5: {
    title: 'Success!',
    image: SuccessImage,
    text: `A representative from Career Scoops will take it from here.`
  },
}
const useCareer = () => {
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.profileReducer);
  const [list, setList] = useState({})
  const [isConfirmModal, setIsConfirmModal] = useState(null)
  const {
    chosenItem,
    results,
    handlescore,
    handleBook,
    handleBack,
    closeSuccessModal,
  } = useResults();
  const params = useParams();
  useEffect(() => {
    if (results?.length) {
      setList(results?.filter(item => item.id === +params.id)[0])
    }
  }, [results, params])
  function handleBookValidation(id, val) {
    if (profile?.pricing_plan || profile?.affiliate_code) {
      handleBook(id, val);
    } else {
      navigate("/pricingplan");
    }
  }
  const handleClose = (val = null) => [
    setIsConfirmModal(val)
  ]
  const handleConfirm = () => {
    if (isConfirmModal === MODAL_TYPE.BOOK_VALIDATION) {
      handleBookValidation(list.id)
      setIsConfirmModal(5)
      return;
    } else if (isConfirmModal === MODAL_TYPE.AI_REPORT) {
      window.open(profile?.ai_report, '_blank');
    } else if (isConfirmModal === MODAL_TYPE.OCCUPATION_PROFILE) {
      window.open(`https://www.careeronestop.org/Toolkit/Careers/Occupations/occupation-profile.aspx?keyword=${list?.unique_title?.title}&onetcode=${list?.unique_title?.id}`, '_blank');
    }
    setIsConfirmModal(null)
  }
  const navigateBack = () => {
    navigate(-1);
  };
  return {
    navigateBack,
    state: list,
    profile,
    chosenItem,
    modalInfo,
    isConfirmModal,
    closeSuccessModal,
    handleClose,
    handleConfirm,
    handlescore,
    handleBack,
    handleBook,
    handleBookValidation,
  };
};

export default useCareer;
