import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";
import { setResults, setResultsSuccess, getResultsError } from "../actions";

export function* setResultsSaga({ payload }) {
  yield put(setResults())
  try {
    const response = yield call(routes.setResults, payload.id, { score: payload.score, booked: payload.booked });
    yield put(setResultsSuccess(response));
  } catch (error) {
    yield put(getResultsError(error));
  }
}