import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import {
  AboutUs,
  Faq,
  Home,
  Story,
  SignUp,
  MentorSignUp,
  NewStudentSignUp,
  StudentSignUp,
  PricingPlan,
  SignIn,
  ForgotPassword,
  Scenario,
  Profile,
} from "./pages";
import Navigation from "./components/Navigation";
import { ParentsStory, StudentsStory } from "./pages/Story/components";
import { FaqItems } from "./pages/Faq/components";
import {
  Sessions,
  Recommendations,
  ProfileInfo,
  MyPlan,
  MyFeedBack,
  EditProfile,
  EditPassword,
  Career,
  Terms,
  Privacy,
  UpdateImage,
} from "./pages/Profile/components";
import { ScenarioResult } from "./pages/Scenario/components";
import Footer from "./components/Footer/Footer";
import "./App.scss";
import CustomSession from "./pages/Profile/components/Sessions/components/CustomSession/CustomSession";
import { FormProvider } from "./contexts/FormContext"; // Correct path for the FormProvider

const App = () => {
  return (
    <FormProvider>
      <div className="App">
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="profile" element={<Profile />}>
            <Route index element={<Recommendations />} />
            <Route path="sessions" element={<Sessions />} />
            <Route path="recommendations" element={<Recommendations />} />
            <Route path="customrequest" element={<CustomSession />} />
            <Route path="recommendations/career/:id" element={<Career />} />
            <Route path="profile-info" element={<ProfileInfo />} />
            <Route path="myPlan" element={<MyPlan />} />
            <Route path="myFeedback" element={<MyFeedBack />} />
            <Route path="EditProfile" element={<EditProfile />} />
            <Route path="editPassword" element={<EditPassword />} />
            <Route path="changePhoto" element={<UpdateImage />} />
          </Route>
          <Route path="signup/mentor" element={<MentorSignUp />} />
          <Route path="signup/student" element={<StudentSignUp />} />
          <Route path="new/student" element={<NewStudentSignUp step={1} />} />
          <Route path="new/student/2" element={<NewStudentSignUp step={2} />} />
          <Route path="signin" element={<SignIn />} />
          <Route path="scenario/:scenarioId" element={<Scenario />} />
          <Route path="results" element={<ScenarioResult />} />
          <Route path="stories" element={<Story />}>
            <Route index element={<StudentsStory />} />
            <Route path="students" element={<StudentsStory />} />
            <Route path="parents" element={<ParentsStory />} />
          </Route>
          <Route path="about" element={<AboutUs />} />
          <Route path="pricingplan/:responseType?" element={<PricingPlan />} />
          <Route path="privacypolicy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="faq" element={<Faq />}>
            <Route index element={<FaqItems />} />
            <Route path="careerexplorer" element={<FaqItems />} />
            <Route path="communitypartner" element={<FaqItems />} />
          </Route>
        </Routes>
        <Footer />
      </div>
    </FormProvider>
  );
};

export default App;
