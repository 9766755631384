import * as actionTypes from "../actionTypes";

export const initPricingPlan = () => ({
  type: actionTypes.INIT_GET_PRICING_PLAN
});

export const getPricingPlan = () => ({
  type: actionTypes.GET_PLANS,
});

export const getPricingPlanSuccess = (payload) => ({
  type: actionTypes.GET_PLANS_SUCCESS,
  payload,
});

export const getPricingPlanError = (error) => ({
  type: actionTypes.GET_PLAN_ERROR,
  error,
});


export const initSetPricingPlan = (data) => ({
  type: actionTypes.INIT_SET_PRICING_PLAN,
  params: data
});

export const setPricingPlan = () => ({
  type: actionTypes.SET_PLAN,
});

export const setPricingPlanSuccess = (payload) => ({
  type: actionTypes.SET_PLAN_SUCCESS,
  payload,
});

export const setPricingPlanError = (error) => ({
  type: actionTypes.SET_PLAN_ERROR,
  error,
});
