import React from "react";
import ScenarioFooter from "../../../../components/ScenarioFooter/ScenarioFooter";
import "./Scenario4.scss";
const Scenario4 = () => {
    return (
        <>
        <section className="Scenario4Intro-cont">
            <div className="Scenario4Intro-cont__textCont">
                <p className="Scenario4Intro-cont__textCont__title">SCENARIO 4</p>
                <p className="Scenario4Intro-cont__textCont__text">Take a look at the picture and rank your top choices that is you!</p>
            </div>
        </section>
        <ScenarioFooter
        variation={"question"} buttons={{ next: { highLightColor: false, text: 'Start' }, back: { text: 'PREV' } }}
        />
        </>
    )
}
export default Scenario4;