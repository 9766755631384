import React from "react";
import { months } from "../../../../../../constants/AppConstants";
import { decideTime } from "../../../../../../common/utils";
import "./SessionHistory.scss";
import Stars from "../../../Recommendations/components/Star/Star";
import useSessionsHistory from "../../../../../../hooks/useSessionsHistory";
import classNames from "classnames";

const SessionHistory = () => {
  const monthsarray = months;

  const { role, sessions, mentorRates, handleRatingChange, naviGate,findMentor } =
    useSessionsHistory();
  return (
    <>
      {sessions.length ? (
        <div className="SessionHistory-cont">
          {sessions.map((el) => {
            return (
              <div className="SessionHistory-cont__items" key={el.id}>
                <div className="SessionHistory-cont__items__dateCont">
                  <p className="SessionHistory-cont__items__dateCont__date">{`${new Date(
                    el.datetime
                  ).getDate()} ${
                    monthsarray[new Date(el.datetime).getMonth()]
                  } ${new Date(el.datetime).getFullYear()}`}</p>
                  {/* <p className="SessionHistory-cont__items__dateCont__duration">{decideTime(el.duaration)}</p> */}
                </div>
                <div
                  className={classNames("SessionHistory-cont__items__info", {
                    mentor__info: role === "Mentor",
                  })}
                >
                  <div className="SessionHistory-cont__items__info__topicCont">
                    <p className="SessionHistory-cont__items__info__topicCont__topic">
                      Topic:
                    </p>
                    <p className="SessionHistory-cont__items__info__topicCont__subject">
                      {el.topic}
                    </p>
                  </div>
                  <div className="SessionHistory-cont__items__info__mentorCont">
                    <p className="SessionHistory-cont__items__info__mentorCont__mentor">
                      {role === "Mentor" ? "Student:" : "Mentor:"}
                    </p>
                    <p className="SessionHistory-cont__items__info__mentorCont__mentorsName">
                      {role === "Mentor" ?  el.student: el?.mentor}
                    </p>
                  </div>
                </div>
                {/* {role === "Mentor" &&
                <> */}
                {/* <div className="SessionHistory-cont__items__lane"></div>
              <div className="SessionHistory-cont__items__StarandRateCont">
                <Stars
                  arr={[1, 2, 3, 4, 5]}
                  onChange={(newRating) => handleRatingChange(el.id, newRating)}
                  rating={mentorRates ? mentorRates[el.id] : ""}
                />
                <p className="SessionHistory-cont__items__StarandRateCont__rate">
                  {mentorRates ? mentorRates[el.id] : ""}
                </p>
              </div> */}
                {/* </>} */}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="emptySessionHistotyCont">
          <div className="emptySessionHistotyCont__emptArea">
            <p className="emptySessionHistotyCont__emptArea__text">
              You don't have Session History yet
            </p>
            {role === "Mentor" ? null : (
              <button
                className="emptySessionHistotyCont__emptArea__btn"
                onClick={() => {
                  naviGate("/scenario/0");
                }}
              >
                <p className="emptySessionHistotyCont__emptArea__btn__text">
                  Start test now
                </p>
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SessionHistory;
