import VideoPlayer from "../../components/VideoPlayer";
import React, { memo, useCallback, useEffect, useRef } from "react";
import Slider from "react-slick";
import closeIcon from "../../assets/Close.svg";
import arrowIco from "../../assets/slider-arrow-icon.svg";
import "./StudentResourcesDialog.scss";
import Loader from "../../components/Loader/loaderSpin";
const sliderFullsettings = {
  dots: false,
  infinite: false,
  arrow: false,
  autoPlay: false,
  adaptiveHeight: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const StudentResourcesDialog = ({ isOpen, onClose, list, handlePagination, currentPage, isLoading }) => {
  const sliderRef = useRef(null)
  useEffect(() => {
    if (isOpen !== null) {
      const timeout = setTimeout(() => {
        sliderRef.current.slickGoTo(isOpen);
      }, 100);
      return () => clearTimeout(timeout);
    }
  }, [isOpen, sliderRef])

  // Handlers for slider navigation
  const handleNextSlide = useCallback((e) => {
    e.stopPropagation();
    sliderRef.current?.slickNext();
  }, []);

  const handlePrevSlide = useCallback((e) => {
    e.stopPropagation();
    sliderRef.current?.slickPrev();
  }, []);
  const handleSlideChange = (slide) => {
    if (slide === (list.results.length - 1) && list.meta.number_of_pages > currentPage) {
      handlePagination()
    }
  }
  if (isOpen === null) {
    return <></>
  }
  return <div className="studentResources__dialog" onClick={onClose}>
    <div className="studentResources__dialog__content" onClick={(e) => e.stopPropagation()}>
      <button onClick={onClose} className="studentResources__dialog__content__imgClose" type='button'>
        <img
          src={closeIcon}
          alt="closeIcon"
        />
      </button>
      <Slider {...sliderFullsettings} beforeChange={handleSlideChange} ref={sliderRef}>
        {list?.results?.map((items, key) => <div key={key}>
          <h3>{items.title}</h3>
          <img src={items.thumbnail} className='coverImg' alt={items.title} />
          <div className="content-des" dangerouslySetInnerHTML={{ __html: items.body }}></div>
          <VideoPlayer
            videoSrc={items.video}
          // thumbnailSrc={items.thumbnail}
          />
        </div>
        )}
      </Slider>
    </div>
    <div className="studentResources__dialog__arrows">
      <button onClickCapture={handlePrevSlide}>
        <img src={arrowIco} alt='prev' className="arrow-prev" width='22px' />
      </button>
      {isLoading ? <div className="profileCont__studentResources__sliderLoading"><Loader/> </div>: <button onClickCapture={handleNextSlide}>
        <img src={arrowIco} alt='next' width='22px' />
      </button>}

    </div>
  </div>
};

export default memo(StudentResourcesDialog);